import { getFields } from "@/apis/field.api";
import { getGroupings } from "@/apis/grouping.api";
import { getRestrictions, syncRestrictionCalculations } from "@/apis/restriction.api";
import { getStocks, listOccupancy } from "@/apis/stock.api";
import { getWaterRights, getWaterRightSnapsotList } from "@/apis/waterright.api";
import { getWells } from "@/apis/well.api";
import { LookupLabel, StatusTag, WellTagList } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { constants } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useFieldLookups from "@/queries/useFieldLookups";
import useGroupingLookupQuery from "@/queries/useGroupingLookupQuery";
import useRestrictionLookups from "@/queries/useRestrictionLookups";
import useStockLookupQuery from "@/queries/useStockLookupQuery";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useWellLookups from "@/queries/useWellLookups";
import { daysOfWeek } from "@/services/utils";
import { Alert, Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedStockConfigurationReport.scss";
import "./GeneratedSummaryReport.scss";

interface Props {
  reportConfig: {
    groupingIds?: string[];
    groupings?: string;
    fieldIds?: string[];
    fields?: string;
    wellIds?: string[];
    wells?: string;
    waterRightIds?: string[];
    waterRights?: string;
    restrictionIds?: string[];
    restrictions?: string;
    stockIds?: string[];
    stock?: string;
    permitTypes?: string;
    priorityTypes?: string;
    generatedDate: string;
    hasAdditionalFilters: boolean;
    year: number;
    wellActive: boolean | null;
    waterRightActive: boolean | null;
    restrictionActive: boolean | null;
    fieldActive: boolean | null;
  };
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedAllConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const currentYear = dayjs().year();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [loadingStock, setLoadingStock] = useState<boolean>(false);
  const [loadingOccupancy, setLoadingOccupancy] = useState<boolean>(false);
  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [loadingRestrictions, setLoadingRestrictions] = useState<boolean>(false);
  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const [loadingGroupings, setLoadingGroupings] = useState<boolean>(false);
  const [loadingRestrictionCalculations, setLoadingRestrictionCalculations] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [tempStock, setStock] = useState<any[]>([]);
  const [occupancy, setOccupancy] = useState<any[]>([]);
  const [tempWaterRights, setWaterRights] = useState<any[]>([]);
  const [tempWells, setWells] = useState<any[]>([]);
  const [tempFields, setFields] = useState<any[]>([]);
  const [tempGroupings, setGroupings] = useState<any[]>([]);
  const [tempRestrictions, setRestrictions] = useState<any[]>([]);
  const [restrictionCalculations, setRestrictionCalculations] = useState<any[]>([]);

  const [waterRightColumns, setWaterRightColumns] = useState<any[]>([]);
  const [groupingColumns, setGroupingColumns] = useState<any[]>([]);
  const [fieldColumns, setFieldColumns] = useState<any[]>([]);
  const [restrictionColumns, setRestrictionColumns] = useState<any[]>([]);
  const [wellColumns, setWellColumns] = useState<any[]>([]);
  const [stockColumns, setStockColumns] = useState<any[]>([]);
  const [headCountsColumns, setHeadCountsColumns] = useState<any[]>([]);

  const { groupingLookup: groupingLookups } = useGroupingLookupQuery();
  const { fields: fieldLookups } = useFieldLookups();
  const { wells: wellLookups } = useWellLookups();
  const { waterRights: waterRightLookups } = useWaterRightLookups();
  const { restrictions: restrictionLookups } = useRestrictionLookups();
  const { stockLookup: stockLookups } = useStockLookupQuery({ searchString: undefined });

  const [snapshots, setSnapshots] = useState<any[]>([]);
  const [loadingSnapshots, setLoadingSnapshots] = useState<boolean>(false);

  const { companyConfigs } = useComapnyConfigLookup();

  const groupings: any[] = useMemo(() => {
    const data = tempGroupings.map((grouping: any) => {
      const waterRightsForGrouping = waterRightLookups.filter((waterRight: any) => grouping.waterRights.includes(waterRight.id)).map((waterRight: any) => waterRight.fileNumber);

      return {
        ...grouping,
        waterRightNames: waterRightsForGrouping?.length > 0 ? waterRightsForGrouping : ["-"],
      };
    });
    return data;
  }, [waterRightLookups, tempGroupings]);

  const restrictions: any[] = useMemo(() => {
    const data = tempRestrictions.map((restriction: any) => {
      const waterRightsForRestriction = tempWaterRights.filter((waterRight) => restriction.waterRightIds.includes(waterRight.id)).map((waterRight) => waterRight.fileNumber);
      const waterRightIds = restriction.waterRightIds;
      const wellsForRestriction = tempWells.filter((well) => waterRightIds.includes(well.waterRightId)).map((well) => well.name);

      return {
        ...restriction,
        waterRights: waterRightsForRestriction?.length > 0 ? waterRightsForRestriction : ["-"],
        wells: wellsForRestriction?.length > 0 ? wellsForRestriction : ["-"],
      };
    });
    return data;
  }, [tempWaterRights, tempWells, tempRestrictions]);

  const fields: any[] = useMemo(() => {
    const data = tempFields.map((field: any) => {
      const waterRightIdsForField = tempWells.filter((well) => well.fieldId === field.id).map((well) => well.waterRightId);
      const waterRightsForField = tempWaterRights.filter((waterRight) => waterRightIdsForField.includes(waterRight.id)).map((waterRight) => waterRight.fileNumber);
      const wellsForField = tempWells.filter((well) => well.fieldId === field.id).map((well) => well.name);

      return {
        ...field,
        waterRights: waterRightsForField?.length > 0 ? waterRightsForField : ["-"],
        wells: wellsForField?.length > 0 ? wellsForField : ["-"],
      };
    });
    return data;
  }, [tempWaterRights, tempWells, tempFields]);

  const waterRights: any[] = useMemo(() => {
    const data = tempWaterRights.map((waterRight: any) => {
      const wellsForWaterRight = tempWells.filter((well) => well.waterRightId === waterRight.id);
      const fieldsForWaterRight = wellsForWaterRight.map((well) => well.fieldId);
      const tempWellsForWr = wellsForWaterRight.map((well) => well.name);
      const tempFieldsForWr = tempFields.filter((field) => fieldsForWaterRight.includes(field.id)).map((field) => field.name);
      const restrictionsForWr = tempRestrictions.filter((restriction) => restriction.waterRightIds.includes(waterRight.id)).map((restriction) => restriction.name);
      const snapshot = snapshots?.find((snapshot: any) => snapshot?.waterRightId === waterRight?.id && snapshot?.year === reportConfig.year);
      const combinedData = { ...waterRight, ...(snapshot || {}) };

      return {
        ...combinedData,
        year: combinedData?.year ?? currentYear,
        wells: tempWellsForWr?.length > 0 ? tempWellsForWr : ["-"],
        fields: tempFieldsForWr.length > 0 ? tempFieldsForWr : ["-"],
        restrictions: restrictionsForWr.length > 0 ? restrictionsForWr : ["-"],
      };
    });
    return data;
  }, [tempWaterRights, tempWells, tempFields, tempRestrictions, snapshots]);

  const wells: any[] = useMemo(() => {
    const data = tempWells.map((well: any) => {
      const waterRightForWell = waterRights.find((waterRight) => waterRight.id === well.waterRightId);
      const fieldForWaterRight = tempFields.find((field) => field.id === well.fieldId)?.name;
      const reductionWells = well.reductionWells ?? [];
      const reductionWellsForWell = tempWells.filter((tempWell) => reductionWells.includes(tempWell.id)).map((tempWell) => tempWell.name);

      return {
        ...well,
        waterRight: waterRightForWell,
        fieldName: fieldForWaterRight ? fieldForWaterRight : ["-"],
        reductionWellNames: reductionWellsForWell.length > 0 ? reductionWellsForWell : ["-"],
      };
    });

    return data;
  }, [tempWells, tempWaterRights, tempFields]);

  const stock: any[] = useMemo(() => {
    const data = tempStock.map((stock: any) => {
      const waterRightsForStock = waterRights.filter((waterRight) => stock.waterRightIds.includes(waterRight.id)).map((waterRight) => waterRight.fileNumber);

      const occupancyForStock = occupancy.filter((occupancy) => stock.id === occupancy.stockId);
      const occupanyDates = _.groupBy(occupancyForStock, "year");
      let occupancyForYear = [];

      for (const [key, value] of Object.entries(occupanyDates)) {
        const year = key;
        const valuesForYear = value;
        occupancyForYear.push({
          year: year,
          januaryHeadCount: valuesForYear.find((value) => value.month === 1)?.headCount ?? "-",
          februaryHeadCount: valuesForYear.find((value) => value.month === 2)?.headCount ?? "-",
          marchHeadCount: valuesForYear.find((value) => value.month === 3)?.headCount ?? "-",
          aprilHeadCount: valuesForYear.find((value) => value.month === 4)?.headCount ?? "-",
          mayHeadCount: valuesForYear.find((value) => value.month === 5)?.headCount ?? "-",
          juneHeadCount: valuesForYear.find((value) => value.month === 6)?.headCount ?? "-",
          julyHeadCount: valuesForYear.find((value) => value.month === 7)?.headCount ?? "-",
          augustHeadCount: valuesForYear.find((value) => value.month === 8)?.headCount ?? "-",
          septemberHeadCount: valuesForYear.find((value) => value.month === 9)?.headCount ?? "-",
          octoberHeadCount: valuesForYear.find((value) => value.month === 10)?.headCount ?? "-",
          novemberHeadCount: valuesForYear.find((value) => value.month === 11)?.headCount ?? "-",
          decemberHeadCount: valuesForYear.find((value) => value.month === 12)?.headCount ?? "-",
          averageHeadCount: valuesForYear.map((value) => value.headCount)?.reduce((partialSum, a) => partialSum + a, 0) / valuesForYear?.length,
        });
      }

      return {
        ...stock,
        waterRightNames: waterRightsForStock?.length > 0 ? waterRightsForStock : ["-"],
        headCounts: occupancyForYear?.length > 0 ? occupancyForYear : null,
      };
    });
    return data;
  }, [waterRights, occupancy, tempStock]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    refreshGroupings();
    refreshFields();
    refreshWells();
    refreshWaterRightsList();
    refreshRestrictions();
    refreshRestrictionCalculations();
    refreshStock();
    refreshOccupancyList();

    if (reportConfig.year < dayjs().year()) {
      refreshSnapshots();
    }
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    calculateWaterRightColumns();
    // eslint-disable-next-line
  }, [waterRights]);

  useEffect(() => {
    calculateWellColumns();
    // eslint-disable-next-line
  }, [wells]);

  useEffect(() => {
    calculateGroupingColumns();
    // eslint-disable-next-line
  }, [groupings, waterRights, tempWaterRights]);

  useEffect(() => {
    calculateFieldColumns();
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    calculateRestrictionColumns();
    // eslint-disable-next-line
  }, [restrictions, restrictionCalculations]);

  useEffect(() => {
    calculateStockColumns();
    // eslint-disable-next-line
  }, [stock, waterRights, occupancy]);

  useEffect(() => {
    calculateHeadCountsColumns();
    // eslint-disable-next-line
  }, [stock]);

  const refreshSnapshots = async () => {
    setLoadingSnapshots(true);

    const request = {
      companyId: selectedCompanyId,
      year: reportConfig.year,
      waterRightIds: reportConfig.waterRightIds,
    };
    const response = await getWaterRightSnapsotList(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setSnapshots(data.value);
      }
    }

    setLoadingSnapshots(false);
  };

  const refreshOccupancyList = async () => {
    setLoadingOccupancy(true);
    if (!reportConfig?.hasAdditionalFilters || (reportConfig?.hasAdditionalFilters && (reportConfig?.stockIds?.length ?? 0) > 0)) {
      const request = { stockIds: reportConfig.stockIds };

      const response = await listOccupancy(request);
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setOccupancy(data.value);
        }
      }
    } else {
      setOccupancy([]);
    }

    setLoadingOccupancy(false);
  };

  const refreshStock = async () => {
    setLoadingStock(true);

    if (!reportConfig?.hasAdditionalFilters || (reportConfig?.hasAdditionalFilters && (reportConfig?.stockIds?.length ?? 0) > 0)) {
      const response = await getStocks({ companyId: selectedCompanyId, stockIds: reportConfig.stockIds });
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setStock(data.value);
        }
      }
    } else {
      setStock([]);
    }

    setLoadingStock(false);
  };

  const refreshRestrictionCalculations = async () => {
    setLoadingRestrictionCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictionCalculations(data.value);
      }
    }
    setLoadingRestrictionCalculations(false);
  };

  const refreshRestrictions = async () => {
    setLoadingRestrictions(true);

    if (!reportConfig?.hasAdditionalFilters || (reportConfig?.hasAdditionalFilters && (reportConfig?.restrictionIds?.length ?? 0) > 0)) {
      const response = await getRestrictions({
        companyId: selectedCompanyId,
        restrictionIds: reportConfig.restrictionIds,
        isActive: reportConfig.restrictionActive ? reportConfig.restrictionActive : undefined,
      });
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setRestrictions(data.value);
        }
      }
    } else {
      setRestrictions([]);
    }

    setLoadingRestrictions(false);
  };

  const refreshGroupings = async () => {
    setLoadingGroupings(true);

    if (!reportConfig?.hasAdditionalFilters || (reportConfig?.hasAdditionalFilters && (reportConfig?.groupingIds?.length ?? 0) > 0)) {
      const response = await getGroupings({ companyId: selectedCompanyId, groupingIds: reportConfig?.groupingIds });
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setGroupings(data.value);
        }
      }
    } else {
      setGroupings([]);
    }

    setLoadingGroupings(false);
  };

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    if (!reportConfig?.hasAdditionalFilters || (reportConfig?.hasAdditionalFilters && (reportConfig?.waterRightIds?.length ?? 0) > 0)) {
      const response = await getWaterRights({
        companyId: selectedCompanyId,
        waterRightIds: reportConfig.waterRightIds,
        isActive: reportConfig.fieldActive ? reportConfig.fieldActive : undefined,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setWaterRights(data.value);
        }
      }
    } else {
      setWaterRights([]);
    }

    setLoadingWaterRights(false);
  };

  const refreshWells = async () => {
    setLoadingWells(true);

    if (!reportConfig?.hasAdditionalFilters || (reportConfig?.hasAdditionalFilters && (reportConfig?.wellIds?.length ?? 0) > 0)) {
      const response = await getWells({ companyId: selectedCompanyId, wellIds: reportConfig.wellIds, isActive: reportConfig.wellActive ? reportConfig.wellActive : undefined });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setWells(data.value);
        }
      }
    } else {
      setWells([]);
    }

    setLoadingWells(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);

    if (!reportConfig?.hasAdditionalFilters || (reportConfig?.hasAdditionalFilters && (reportConfig?.fieldIds?.length ?? 0) > 0)) {
      const response = await getFields({ companyId: selectedCompanyId, fieldIds: reportConfig.fieldIds, isActive: reportConfig.fieldActive ? reportConfig.fieldActive : undefined });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setFields(data.value);
        }
      }
    } else {
      setFields([]);
    }

    setLoadingFields(false);
  };

  const determineRestrictionValue = (id: string, type: string, postfix: any = undefined) => {
    const existingCalculation = restrictionCalculations.find((calculation) => calculation.id === id);
    if (existingCalculation) {
      return existingCalculation[type];
    } else return undefined;
  };

  const calculateStockColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Stock Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 300,
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
          </>
        ),
      },
      {
        title: "Water Rights",
        key: "waterRightNames",
        dataIndex: "waterRightNames",
        render: (val: any) => val && <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{val.map((x: any) => x).join(", ")}</div>,
      },
    ];

    setStockColumns(tempColumns);
  };

  const calculateHeadCountsColumns = () => {
    let tempColumns = [
      {
        title: "Head Counts",
        children: [
          {
            title: "Year",
            key: "year",
            dataIndex: "year",
            render: (val: any, record: any) => val,
          },
          {
            title: <>January</>,
            key: "januaryHeadCount",
            dataIndex: "januaryHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>February</>,
            key: "februaryHeadCount",
            dataIndex: "februaryHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>March</>,
            key: "marchHeadCount",
            dataIndex: "marchHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>April</>,
            key: "aprilHeadCount",
            dataIndex: "aprilHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>May</>,
            key: "mayHeadCount",
            dataIndex: "mayHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>June</>,
            key: "juneHeadCount",
            dataIndex: "juneHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>July</>,
            key: "julyHeadCount",
            dataIndex: "julyHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>August</>,
            key: "augustHeadCount",
            dataIndex: "augustHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>September</>,
            key: "septemberHeadCount",
            dataIndex: "septemberHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>October</>,
            key: "octoberHeadCount",
            dataIndex: "octoberHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>November</>,
            key: "novemberHeadCount",
            dataIndex: "novemberHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>December</>,
            key: "decemberHeadCount",
            dataIndex: "decemberHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>Average</>,
            key: "averageHeadCount",
            dataIndex: "averageHeadCount",
            render: (val: any, record: any) => val,
          },
        ],
      },
    ];

    setHeadCountsColumns(tempColumns);
  };

  const calculateRestrictionColumns = () => {
    let tempColumns = [
      {
        title: "#",
        // fixed: 'left',
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Restriction Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
          </>
        ),
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (val: string, record: any) => {
          if (!record?.myfa) return <LookupLabel lookupType="restrictiontype" value={val} />;
          else
            return (
              <>
                <LookupLabel lookupType="restrictiontype" value={val} /> - MYFA
              </>
            );
        },
      },
      {
        title: "Start Date",
        key: "startDate",
        dataIndex: "startDate",
        render: (val: any, record: any) => dayjs(record.startDate).format(constants.dateFormat),
      },
      {
        title: "End Date",
        key: "endDate",
        dataIndex: "endDate",
        render: (val: any, record: any) => dayjs(record.endDate).format(constants.dateFormat),
      },
      {
        title: "Years",
        render: (val: any, record: any) => determineRestrictionValue(record?.id, "years"),
      },
      {
        title: (
          <>
            Term Quantity <small>(Acre Feet)</small>
          </>
        ),
        key: "termQuantity",
        dataIndex: "termQuantity",
        align: "right",
        width: 300,
        render: (val: any, record: any) => val,
      },
    ];

    setRestrictionColumns(tempColumns);
  };

  const calculateFieldColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Field Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 300,
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
          </>
        ),
      },
      {
        title: "Water Rights",
        key: "waterRights",
        dataIndex: "waterRights",
        render: (val: any) => val && <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{val?.map((x: any) => x).join(", ")}</div>,
      },
      {
        title: "Wells / Meters",
        key: "wells",
        dataIndex: "wells",
        render: (val: any) => val && <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{val?.map((x: any) => x).join(", ")}</div>,
      },
    ];

    setFieldColumns(tempColumns);
  };

  const calculateGroupingColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Grouping Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 300,
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
          </>
        ),
      },
      {
        title: "Water Rights",
        key: "waterRightNames",
        dataIndex: "waterRightNames",
        render: (val: any) => val && <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{val.map((x: any) => x).join(", ")}</div>,
      },
    ];

    setGroupingColumns(tempColumns);
  };

  const calculateWellColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Well Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
            <WellTagList style={{ maxWidth: 200 }} tags={record?.tags} />
          </>
        ),
      },
      {
        title: "Field Name",
        key: "fieldName",
        dataIndex: "fieldName",
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "waterRight",
        dataIndex: "waterRight",
        render: (val: any, record: any) => (
          <>
            <div style={{ paddingLeft: 5 }}>{record?.waterRight?.fileNumber}</div>
            <div style={{ paddingLeft: 5 }}>PDIV: {record?.waterRight?.pdiv ? record.waterRight.pdiv : "-"}</div>
            <div style={{ paddingLeft: 5 }}>CIN: {record?.waterRight?.cin ? record.waterRight.cin : "-"}</div>
          </>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} />,
      },
      {
        title: (
          <>
            Meter <br />
            Max <br />
            Value
          </>
        ),
        key: "meterMaxValue",
        dataIndex: "meterMaxValue",
        align: "right",
        render: (val: any, record: any) => <LookupLabel lookupType="metermaxvalue" value={record?.meterMaxValue} />,
      },
      {
        title: (
          <>
            Meter <br />
            Multiplier
          </>
        ),
        key: "meterMultiplier",
        dataIndex: "meterMultiplier",
        align: "right",
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Is <br />
            Digital <br />
            Meter
          </>
        ),
        key: "digital",
        dataIndex: "digital",
        render: (val: any, record: any) => <StatusTag status={val === true ? "YES" : "No"} />,
      },
      {
        title: (
          <>
            Number <br />
            of <br />
            Decimals
          </>
        ),
        key: "decimals",
        dataIndex: "decimals",
        render: (val: any, record: any) => (val ? val : "N/A"),
      },
      {
        title: (
          <>
            Meter <br />
            Units
          </>
        ),
        key: "meterUnits",
        dataIndex: "meterUnits",
        render: (val: any, record: any) => <LookupLabel lookupType="meterunits" value={record?.meterUnits} />,
      },
      {
        title: (
          <>
            Meter <br />
            Serial <br />
            Number
          </>
        ),
        key: "meterSerialNumber",
        dataIndex: "meterSerialNumber",
        render: (val: any, record: any) => (val ? val : "-"),
      },
      {
        title: (
          <>
            Latitude <br />
            Longitude
          </>
        ),
        // align: 'right',
        render: (val: any, record: any) => {
          if (record?.latitude && record?.longitude) {
            return (
              <>
                {record.latitude},
                <br />
                {record.longitude}
              </>
            );
          } else return "-";
        },
      },
      {
        title: (
          <>
            Meter Reading <br />
            Frequency
          </>
        ),
        key: "meterMultiplier",
        dataIndex: "meterMultiplier",
        align: "right",
        render: (val: any, record: any) => {
          const companyConfig = companyConfigs?.find((company: any) => company?.id === record?.companyId);

          if (record?.meterReadingFrequency) {
            return (
              <>
                <LookupLabel lookupType={"meterReadingFrequency"} value={record?.meterReadingFrequency} />
                <br />
                {record?.meterReadingFrequencyDay ? record?.meterReadingFrequencyDay : daysOfWeek.find((day) => day.value === record?.meterReadingFrequencyDayOfWeek)?.day}
              </>
            );
          } else if (companyConfig?.meterReadingFrequency) {
            return <>{companyConfig?.meterReadingFrequency ? companyConfig?.meterReadingFrequencyDay : daysOfWeek.find((day) => day.value === companyConfig?.meterReadingFrequencyDayOfWeek)?.day} *</>;
          } else return <>-</>;
        },
      },
    ];

    setWellColumns(tempColumns.filter((x) => x));
  };

  const calculateWaterRightColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "fileNumber",
        dataIndex: "fileNumber",
        render: (val: any, record: any) => (
          <>
            <div style={{ paddingLeft: 5 }}>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11, paddingLeft: 5 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
            <div style={{ paddingLeft: 5 }}>PDIV: {record?.pdiv ? record.pdiv : "-"}</div>
            <div style={{ paddingLeft: 5 }}>CIN: {record?.cin ? record?.cin : "-"}</div>
          </>
        ),
      },
      {
        title: "Legal",
        key: "state",
        dataIndex: "state",
        render: (val: any, record: any) =>
          val ? (
            <>
              {record?.section} - {record?.township}
              {record?.townshipDirection} - {record?.range}
              {record?.rangeDirection}
              <br />
              {val ? <LookupLabel showCode={false} lookupType="states" value={val} /> : ""}
            </>
          ) : (
            "-"
          ),
      },
      {
        title: (
          <>
            Priority <br />
            Type
          </>
        ),
        key: "priorityType",
        dataIndex: "priorityType",
        render: (val: any, record: any) => <LookupLabel lookupType="priorityType" value={val} />,
      },
      {
        title: (
          <>
            Permit <br />
            Type
          </>
        ),
        key: "permitType",
        dataIndex: "permitType",
        render: (val: any, record: any) => <LookupLabel lookupType="permitType" value={val} />,
      },
      {
        title: (
          <>
            System <br />
            Type
          </>
        ),
        key: "systemType",
        dataIndex: "systemType",
        render: (val: any, record: any) => <LookupLabel lookupType="systemType" value={val} />,
      },
      {
        title: (
          <>
            Crop <br />
            Type
          </>
        ),
        key: "cropType",
        dataIndex: "cropType",
        render: (val: any, record: any) => <LookupLabel lookupType="cropType" value={val} />,
      },
      {
        title: (
          <>
            End <br />
            Guns
          </>
        ),
        key: "endGuns",
        dataIndex: "endGuns",
        render: (val: any, record: any) => val ?? "-",
      },
      {
        title: (
          <>
            Energy <br />
            Type
          </>
        ),
        key: "energyType",
        dataIndex: "energyType",
        render: (val: any, record: any) => <LookupLabel lookupType="energyType" value={val} />,
      },
      {
        title: "Owner",
        key: "owner",
        dataIndex: "owner",
        render: (val: any, record: any) => <LookupLabel lookupType="owner" value={val} />,
      },
      {
        title: "Water Source",
        key: "waterSource",
        dataIndex: "waterSource",
        render: (val: any, record: any) => <LookupLabel lookupType="waterSource" value={val} />,
      },
      {
        title: "GMD",
        key: "gmd",
        dataIndex: "gmd",
        render: (val: any, record: any) => <LookupLabel lookupType="gmd" value={val} />,
      },
      {
        title: (
          <>
            Subject <br />
            to <br />
            MDS
          </>
        ),
        key: "subjectToMds",
        dataIndex: "subjectToMds",
        render: (val: any, record: any) => <StatusTag status={val ? "Yes" : "No"} color={val ? "blue" : "green"} />,
      },
      {
        title: (
          <>
            Warnings <br />
            Disabled
          </>
        ),
        key: "disableWarnings",
        dataIndex: "disableWarnings",
        render: (val: any, record: any) =>
          val ? (
            <>
              <StatusTag status={"Yes"} />
              <br />
            </>
          ) : (
            <StatusTag status={"No"} />
          ),
      },
      {
        title: (
          <>
            Authorized
            <br />
            <small>Irrigation Acres</small>
            <br />
            <small>Quantity (Acre Feet)</small>
            <br />
            <small>Rate (GPM)</small>
          </>
        ),
        key: "authorized",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === record?.year)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? "N/A" : <UnitsConverter fromUnits={record?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} />}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate}</div>}
              </>
            );
          else if (record?.year < currentYear && record?.referenceYear)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? "N/A" : <UnitsConverter fromUnits={record?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} />}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate}</div>}
              </>
            );
          else
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : `${record?.authorizedIrrigationAcres} *`}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    <UnitsConverter fromUnits={record?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} /> *
                  </>
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate} *</div>}
              </>
            );
        },
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>Irrigation Acres</small>
            <br />
            <small>Quantity (Acre Feet)</small>
            <br />
            <small>Rate (GPM)</small>
          </>
        ),
        key: "usage",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === record?.year)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ?? record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter
                    fromUnits={record?.usageQuantity ? record?.usageQuantityUnits : record?.authorizedQuantityUnits}
                    toUnits="acrefeet"
                    value={record?.usageQuantity ?? record?.authorizedQuantity}
                  />
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ?? record?.authorizedRate}</div>}
              </>
            );
          else if (record?.year < currentYear && record?.referenceYear)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ?? record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter
                    fromUnits={record?.usageQuantity ? record?.usageQuantityUnits : record?.authorizedQuantityUnits}
                    toUnits="acrefeet"
                    value={record?.usageQuantity ?? record?.authorizedQuantity}
                  />
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ?? record?.authorizedRate}</div>}
              </>
            );
          else
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ? `${record?.usageIrrigationAcres} *` : `${record?.authorizedIrrigationAcres} *`}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    <UnitsConverter
                      fromUnits={record?.usageQuantity ? record?.usageQuantityUnits : record?.authorizedQuantityUnits}
                      toUnits="acrefeet"
                      value={record?.usageQuantity ?? record?.authorizedQuantity}
                    />{" "}
                    *
                  </>
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ? `${record?.usageRate} *` : `${record?.authorizedRate} *`}</div>}
              </>
            );
        },
      },
    ];

    setWaterRightColumns(tempColumns);
  };

  const renderExpandData = (record: any) => {
    return (
      <>
        <div
          style={{
            padding: 0,
            margin: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <StatusTag style={{ margin: 2, maxWidth: 200 }} status={record?.status} />
          <WaterRightTagList internalTags={record.internalTags} externalTags={record.externalTags} style={{ marginLeft: 0 }} />
        </div>
        <div style={{ padding: 5 }}>
          {/* {record?.pdiv && <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ fontSize: 12, width: 100, fontWeight: 'bold', padding: 0 }}>PDIV: </div>
                    <div style={{ fontSize: 12, fontWeight: 'normal', flex: 1 }}>{record?.pdiv}</div>
                </div>}
                {record?.cin && <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ fontSize: 12, width: 100, fontWeight: 'bold', padding: 0 }}>CIN: </div>
                    <div style={{ fontSize: 12, fontWeight: 'normal', flex: 1 }}>{record?.cin}</div>
                </div>} */}
          {record?.wells?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 100,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Wells / Meters:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.wells?.map((x: any) => x).join(", ")}</div>
            </div>
          )}
          {record?.restrictions?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 100,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Restrictions:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.restrictions?.map((x: any) => x).join(", ")}</div>
            </div>
          )}
          {record?.restrictions?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 100,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Fields:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.fields?.map((x: any) => x).join(", ")}</div>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderWellExpandData = (record: any) => {
    return (
      <div style={{ padding: 5 }}>
        {record?.reductionWellNames?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 160,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Deduction Wells / Meters:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.reductionWellNames?.map((x: any) => x).join(", ")}</div>
          </div>
        )}
      </div>
    );
  };

  const renderRestrictionExpandData = (record: any) => {
    return (
      <div style={{ padding: 5 }}>
        {record?.waterRights?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 100,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Water Rights:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.waterRights?.map((x: any) => x).join(", ")}</div>
          </div>
        )}
        {record?.wells?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 100,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Wells / Meters:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.wells?.map((x: any) => x).join(", ")}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`All Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loadingWells && loadingWaterRights && loadingGroupings && loadingFields && loadingRestrictions}
        >
          {
            <>
              <Card title="Groupings">
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.id}
                    loading={loadingGroupings}
                    columns={groupingColumns}
                    dataSource={groupings ?? []}
                    size="small"
                    pagination={false}
                  />
                </ConfigProvider>
              </Card>
              <div className="page-break" />
            </>
          }

          {
            <>
              <Card title="Fields">
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.id}
                    loading={loadingFields}
                    columns={fieldColumns}
                    dataSource={fields ?? []}
                    size="small"
                    pagination={false}
                  />
                </ConfigProvider>
              </Card>
              <div className="page-break" />
            </>
          }

          {
            <>
              <Card title="Wells / Meters">
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.id}
                    loading={loadingWells}
                    columns={wellColumns}
                    dataSource={wells ?? []}
                    size="small"
                    pagination={false}
                    expandable={{
                      showExpandColumn: false,
                      expandedRowKeys: reportConfig.wellIds
                        ? wells.filter((well) => reportConfig.wellIds?.includes(well.id) && well.reductionWellNames?.length > 0 && well.reductionWellNames[0] !== "-").map((well) => well.id)
                        : [],
                      expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                      expandedRowRender: (record: any) => renderWellExpandData(record),
                    }}
                  />
                </ConfigProvider>
              </Card>
              <div className="page-break" />
            </>
          }

          {
            <>
              <Card title="Water Rights">
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.id}
                    loading={loadingWaterRights}
                    columns={waterRightColumns}
                    dataSource={waterRights ?? []}
                    size="small"
                    pagination={false}
                    expandable={{
                      showExpandColumn: false,
                      defaultExpandAllRows: true,
                      expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                      expandedRowRender: (record: any) => renderExpandData(record),
                    }}
                    footer={() => {
                      if (reportConfig?.year < currentYear)
                        return (
                          <Alert
                            description={`The Authorized and Usage columns may contain "*" alongside the values if a snapshot is configured for the water right for any year range that is not the current year (${currentYear})`}
                            type="warning"
                            showIcon
                          />
                        );
                      else return undefined;
                    }}
                  />
                </ConfigProvider>
              </Card>
              <div className="page-break" />
            </>
          }

          {
            <>
              <Card title="Restrictions">
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.id}
                    loading={loadingRestrictions}
                    columns={restrictionColumns}
                    dataSource={restrictions ?? []}
                    size="small"
                    pagination={false}
                    expandable={{
                      showExpandColumn: false,
                      defaultExpandAllRows: true,
                      expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                      expandedRowRender: (record: any) => renderRestrictionExpandData(record),
                    }}
                  />
                </ConfigProvider>
              </Card>
              <div className="page-break" />
            </>
          }

          {
            <>
              <Card title="Stock">
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.id}
                    loading={loadingStock}
                    columns={stockColumns}
                    dataSource={stock ?? []}
                    size="small"
                    pagination={false}
                    expandable={{
                      showExpandColumn: false,
                      expandedRowKeys: reportConfig.stockIds ? stock.filter((stock) => stock.headCounts !== null).map((stock) => stock.id) : [],
                      expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                      expandedRowRender: (record: any, index: number) => {
                        const headerClassName = index % 2 === 0 ? "headerColorWhite" : "headerColorBlue";
                        return <Table className={headerClassName} dataSource={record?.headCounts} columns={headCountsColumns} pagination={false} />;
                      },
                    }}
                  />
                </ConfigProvider>
              </Card>
              <div className="page-break" />
            </>
          }

          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Groupings">{reportConfig.groupings === "" ? "-" : reportConfig.groupings}</Descriptions.Item>
                <Descriptions.Item label="Fields">{reportConfig.fields === "" ? "-" : reportConfig.fields}</Descriptions.Item>
                <Descriptions.Item label="Wells">{reportConfig.wells === "" ? "-" : reportConfig.wells}</Descriptions.Item>
                <Descriptions.Item label="Water Rights">{reportConfig.waterRights === "" ? "-" : reportConfig.waterRights}</Descriptions.Item>
                <Descriptions.Item label="Restrictions">{reportConfig.restrictions === "" ? "-" : reportConfig.restrictions}</Descriptions.Item>
                <Descriptions.Item label="Stock">{reportConfig.stock === "" ? "-" : reportConfig.stock}</Descriptions.Item>
                <Descriptions.Item label="Permit Types">{reportConfig?.permitTypes === "" ? "-" : reportConfig?.permitTypes}</Descriptions.Item>
                <Descriptions.Item label="Priority Types">{reportConfig?.priorityTypes === "" ? "-" : reportConfig?.priorityTypes}</Descriptions.Item>
                <Descriptions.Item label="Year">{reportConfig.year}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
