import { syncWaterRightCalculation } from "@/apis/waterright.api";
import { getEndOfYearReadings, getRecentWellReading } from "@/apis/well.api";
import { LastReadingFrequency } from "@/components";
import PrintPage from "@/components/PrintPage/PrintPage";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useFieldLookups from "@/queries/useFieldLookups";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useWellLookups from "@/queries/useWellLookups";
import { formatReadingValue } from "@/services/utils";
import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { uniqueId } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedEndOfYearMeterReadingReport.scss";
import CompanyLabel from "@/components/Lookups/CompanyLabel";

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const GeneratedEndOfYearMeterReadingReport: FC<Props> = (props) => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { reportConfig } = props;

  const [recentWellReadings, setRecentWellReadings] = useState<any[]>([]);
  const [tempEndOfYearWellReadings, setEndOfYearWellReadings] = useState<any[]>([]);
  const [calculations, setCalulations] = useState<any[]>([]);
  const [loadingRecentWellReadings, setLoadingRecentWellReadings] = useState<boolean>(false);
  const [loadingCalculations, setLoadingCalculations] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [wellColumns, setWellColumns] = useState<any[]>([]);

  const { wells: tempWells } = useWellLookups(undefined, selectedCompanyId);
  const { fields } = useFieldLookups(undefined, selectedCompanyId);
  const { waterRights } = useWaterRightLookups();
  const { companyConfigs } = useComapnyConfigLookup();

  const wells: any[] = useMemo(() => {
    let finalList: any[] = [];

    tempWells
      ?.sort((a: any, b: any) =>
        a.waterRightId.localeCompare(b.waterRightId, "en", {
          numeric: true,
          sensitivity: "base",
        })
      )
      ?.forEach((well: any) => {
        let showDetails = false;
        const fieldName = fields.find((field: any) => well.fieldId === field.id)?.name;
        const waterRightFileNumber = waterRights.find((waterRight: any) => well.waterRightId === waterRight.id)?.fileNumber;

        if (waterRightFileNumber === undefined) {
          return;
        }

        const lastReading = recentWellReadings
          ?.filter((reading: any) => reading.wellId === well.id && dayjs(reading.date).year() <= reportConfig?.years[1])
          .sort((a: any, b: any) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))?.[0];

        for (let index = reportConfig?.years[1]; index >= reportConfig?.years[0]; index--) {
          const year = index;
          const currentYearLastReading = tempEndOfYearWellReadings
            ?.filter((reading: any) => reading.wellId === well.id && dayjs(reading.date).year() === year)
            ?.sort((a: any, b: any) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))?.[0];
          const currentYearFirstReading = tempEndOfYearWellReadings
            ?.filter((reading: any) => reading.wellId === well.id && dayjs(reading.date).year() === year)
            ?.sort((a: any, b: any) => (dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1))?.[0];
          const previousYearReadings = tempEndOfYearWellReadings?.filter((reading: any) => reading.wellId === well.id && dayjs(reading.date).year() === year - 1);
          const previousYearLastReading = previousYearReadings?.sort((a: any, b: any) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))?.[0];

          finalList.push({
            id: uniqueId(),
            companyId: well?.companyId,
            wellId: well.id,
            wellName: well.name,
            year,
            fieldName,
            fieldId: well.fieldId,
            waterRightId: well?.waterRightId,
            waterRightFileNumber,
            lastReadingDate: reportConfig?.years[1] === year ? lastReading?.date : undefined,
            lastReadingValue: reportConfig?.years[1] === year ? lastReading?.reading : undefined,
            lastReadingDecimals: reportConfig?.years[1] === year ? lastReading?.decimals : undefined,
            endingReading: currentYearLastReading,
            beginningReading: previousYearLastReading ?? currentYearFirstReading,
          });
        }
      });

    var filteredList = finalList.filter((x) => reportConfig?.wellIds?.includes(x.wellId));

    const orderedList = filteredList.sort((a: any, b: any) => {
      let result = a?.waterRightFileNumber?.localeCompare(b?.waterRightFileNumber, "en", { numeric: true, sensitivity: "base" });
      if (result === 0)
        result = a?.fieldName?.localeCompare(b?.fieldName, "en", {
          numeric: true,
          sensitivity: "base",
        });
      if (result === 0)
        result = a?.name?.localeCompare(b?.name, "en", {
          numeric: true,
          sensitivity: "base",
        });
      if (result === 0) result = a?.year - b?.year;
      return result;
    });

    let indexCounter = 1;
    const indexedList = orderedList.map((item, index, list) => {
      return {
        ...item,
        index: item.waterRightId === list?.[index - 1]?.waterRightId && item.fieldId === list?.[index - 1]?.fieldId && item.wellId === list?.[index - 1]?.wellId ? "" : indexCounter++,
        indexCounter,
      };
    });

    return indexedList;
  }, [tempWells, recentWellReadings, waterRights, tempEndOfYearWellReadings, reportConfig, fields]);

  useEffect(() => {
    if (reportConfig) {
      refreshCalculations();
    }
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    if (tempWells?.length > 0) refreshRecentWellReadings();
    refreshEndOfYearWellReadings();
    // eslint-disable-next-line
  }, [tempWells]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    calculateWellColumns();
    // eslint-disable-next-line
  }, [wells, calculations]);

  const refreshCalculations = async () => {
    setLoadingCalculations(true);
    const response = await syncWaterRightCalculation({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCalulations(data.value);
      }
    }
    setLoadingCalculations(false);
  };

  const refreshRecentWellReadings = async () => {
    setLoadingRecentWellReadings(true);

    const request = {
      wellIds: tempWells?.map((well: any) => well?.id),
      year: reportConfig?.year,
    };

    const response = await getRecentWellReading(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRecentWellReadings(data.value);
      }
    }

    setLoadingRecentWellReadings(true);
  };

  const refreshEndOfYearWellReadings = async () => {
    setLoadingRecentWellReadings(true);

    const request = {
      wellIds: reportConfig?.wellIds,
      startYear: reportConfig?.years[0] - 1, //TODO Ryan - The backend needs to bring back the correct readings. If the last reading was taken in 2020 and we add a new reading in 2024, this will not show the 2020 as the beginning reading thus it needs to correctly bring back the readings - I have added -1 to cater for single readings in the previous year, but this will not always work, this is not a priority though, please add it to the list for maintenance when there is time to do it
      endYear: reportConfig?.years[1],
    };

    const response = await getEndOfYearReadings(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setEndOfYearWellReadings(data.value);
      }
    }

    setLoadingRecentWellReadings(false);
  };

  const determineValue = (
    id: string,
    type: string,
    year: any,
    postfix: any = undefined,
    irrigationAcresNotApplicable: boolean | undefined = undefined,
    quantityNotApplicable: boolean | undefined = undefined,
    rateNotApplicable: boolean | undefined = undefined
  ) => {
    const existingCalculation = calculations.find((calculation) => calculation.waterRightId === id && year === calculation.year);
    if (existingCalculation) {
      if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (existingCalculation.quantityNotApplicable === true || existingCalculation.rateNotApplicable === true)) return "N/A";
      return existingCalculation[type];
    } else {
      if (type === "remainingUsage" && quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (quantityNotApplicable === true || rateNotApplicable === true)) return "N/A";
      else return undefined;
    }
  };

  const calculateWaterRightAvailablePercentage = (record: any) => {
    if (record?.waterRightData?.authorizedQuantityNotApplicable === true) return "N/A";
    else return ((determineValue(record?.waterRightId, "remainingUsage", record?.year) / determineValue(record?.waterRightId, "availableQuantity", record?.year)) * 100).toFixed(2);
  };

  const calculateWellColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        key: "index",
        dataIndex: "index",
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "waterRightFileNumber",
        dataIndex: "waterRightFileNumber",
        render: (val: any, record: any, index: number) =>
          index > 0 ? (
            record.waterRightId === wells?.[index - 1]?.waterRightId && record.fieldId === wells?.[index - 1]?.fieldId && record.wellId === wells?.[index - 1]?.wellId ? (
              ""
            ) : (
              <>
                <div>{val}</div>
                {!selectedCompanyId && (
                  <div style={{ fontSize: 11 }}>
                    (<CompanyLabel companyId={record?.companyId} />)
                  </div>
                )}
              </>
            )
          ) : (
            <>
              <div>{val}</div>
              {!selectedCompanyId && (
                <div style={{ fontSize: 11 }}>
                  (<CompanyLabel companyId={record?.companyId} />)
                </div>
              )}
            </>
          ),
      },
      {
        title: "Field Name",
        key: "fieldName",
        dataIndex: "fieldName",
        render: (val: any, record: any, index: number) =>
          index > 0 ? (record.waterRightId === wells?.[index - 1]?.waterRightId && record.fieldId === wells?.[index - 1]?.fieldId && record.wellId === wells?.[index - 1]?.wellId ? "" : val) : val,
      },
      {
        title: "Well Name",
        key: "wellName",
        dataIndex: "wellName",
        render: (val: any, record: any, index: number) =>
          index > 0 ? (record.waterRightId === wells?.[index - 1]?.waterRightId && record.fieldId === wells?.[index - 1]?.fieldId && record.wellId === wells?.[index - 1]?.wellId ? "" : val) : val,
      },
      {
        title: "Last Reading Date",
        key: "wellDate",
        dataIndex: "lastReadingDate",
        render: (val: any, record: any) => {
          const companyConfig = companyConfigs?.find((company: any) => company?.id === record?.companyId);

          const meterReadingFrequency = record.meterReadingFrequency ? record.meterReadingFrequency : companyConfig?.settings?.meterReadingFrequency;
          const meterReadingFrequencyDay = record.meterReadingFrequency ? record.meterReadingFrequencyDay : companyConfig?.settings?.meterReadingFrequencyDay;
          const meterReadingFrequencyDayOfWeek = record.meterReadingFrequency ? record.meterReadingFrequencyDayOfWeek : companyConfig?.settings?.meterReadingFrequencyDayOfWeek;
          const timezone = companyConfig?.settings?.timezone;

          return record.year === reportConfig?.years[1] ? (
            val ? (
              <LastReadingFrequency
                isReport
                type="well"
                date={val}
                meterReadingFrequency={meterReadingFrequency}
                meterReadingFrequencyDay={meterReadingFrequencyDay}
                meterReadingFrequencyDayOfWeek={meterReadingFrequencyDayOfWeek}
                timezone={timezone}
              />
            ) : (
              "No Readings Captured"
            )
          ) : (
            ""
          );
        },
      },
      {
        title: "Last Reading Value",
        key: "wellReading",
        dataIndex: "lastReadingValue",
        render: (val: any, record: any) =>
          record.year === reportConfig?.years[1]
            ? val
              ? formatReadingValue({
                  reading: val,
                  decimals: record?.lastReadingDecimals,
                })
              : "No Readings Captured"
            : "",
      },
      {
        title: "Year",
        key: "year",
        dataIndex: "year",
        render: (val: any, record: any) => val,
      },
      {
        title: "Beginning Reading",
        key: "beginningReading",
        dataIndex: "beginningReading",
        render: (val: any, record: any) => (val?.reading ? formatReadingValue(val) : "-"),
      },
      {
        title: "Ending Reading",
        key: "endingReading",
        dataIndex: "endingReading",
        render: (val: any, record: any) => (val?.reading ? formatReadingValue(val) : "-"),
      },
      {
        title: "Used",
        key: "used",
        dataIndex: "used",
        render: (val: any, record: any) => {
          const companyConfig = companyConfigs?.find((company: any) => company?.id === record?.companyId);

          return (
            <>
              {companyConfig?.settings?.metric !== "gallons" && (
                <>
                  <UnitsConverter fromUnits="gallons" toUnits={"gallons"} value={determineValue(record.waterRightId, "currentUsage", record.year)} showUnitsLabel />
                  <br />
                </>
              )}
              <UnitsConverter fromUnits="gallons" toUnits={companyConfig?.settings?.metric} value={determineValue(record.waterRightId, "currentUsage", record.year)} showUnitsLabel />
            </>
          );
        },
      },

      {
        title: (
          <>
            Remaining
            <br />
            <small>Days</small>
            <br />
            <small>%</small>
          </>
        ),
        key: "remainingDays",
        dataIndex: "remainingDays",
        align: "center",
        render: (val: any, record: any) => {
          const percentage = calculateWaterRightAvailablePercentage(record);

          if (determineValue(record?.waterRightId, "remainingDays", record.year) === undefined)
            return (
              <>
                - <br /> 0.00%
              </>
            );
          else
            return (
              <>
                <RemainingDays remainingDays={determineValue(record?.waterRightId, "remainingDays", record.year)} /> <br />{" "}
                {+percentage > 0 ? `${percentage}%` : percentage === "N/A" ? "N/A" : "0.00%"}
              </>
            );
        },
      },
    ];

    setWellColumns(tempColumns.filter((x) => x).map((col) => ({ ...col, onCell: undefined })));
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedLastReadingReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`End Of Year Meter Reading Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
        >
          {
            <Card title="End Of Year Meter Readings">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (record.indexCounter % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  dataSource={wells ?? []}
                  columns={wellColumns}
                  loading={loadingCalculations}
                  size="small"
                  pagination={false}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Year Range">
                  {reportConfig?.years[0]} - {reportConfig.years[1]}
                </Descriptions.Item>
                <Descriptions.Item label="Wells">{reportConfig?.selectedWellNames === "" ? "-" : reportConfig.selectedWellNames}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedEndOfYearMeterReadingReport;
