import { getWaterRights, getWaterRightSnapsotList } from "@/apis/waterright.api";
import { LookupLabel, StatusTag } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import useFieldLookups from "@/queries/useFieldLookups";
import useRestrictionLookups from "@/queries/useRestrictionLookups";
import useWellLookups from "@/queries/useWellLookups";
import { Alert, Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import "./GeneratedSummaryReport.scss";
import useWaterRightLookups from "@/queries/useWaterRightLookups";

interface Props {
  reportConfig: {
    waterRightIds?: string[];
    waterRights: string;
    permitTypes: string;
    priorityTypes: string;
    generatedDate: string;
    year: number;
    active: boolean | null;
  };
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedWaterRightConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const currentYear = dayjs().year();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { restrictions } = useRestrictionLookups();
  const { fields } = useFieldLookups();
  const { wells } = useWellLookups();
  const { waterRights: wrLookups } = useWaterRightLookups(undefined, selectedCompanyId, reportConfig.active ? reportConfig.active : undefined);

  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(true);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [waterRightIds, setWaterRightIds] = useState<string[]>([]);
  const [tempWaterRights, setWaterRights] = useState<any[]>([]);

  const [snapshots, setSnapshots] = useState<any[]>([]);
  const [loadingSnapshots, setLoadingSnapshots] = useState<boolean>(false);

  const [waterRightColumns, setWaterRightColumns] = useState<any[]>([]);

  const waterRights: any[] = useMemo(() => {
    const data = tempWaterRights.map((waterRight: any) => {
      const wellsForWaterRight = wells.filter((well: any) => well.waterRightId === waterRight.id);
      const fieldsForWaterRight = wellsForWaterRight.map((well: any) => well.fieldId);
      const tempWellsForWr = wellsForWaterRight.map((well: any) => well.name);
      const tempFieldsForWr = fields.filter((field: any) => fieldsForWaterRight.includes(field.id)).map((field: any) => field.name);
      const restrictionsForWr = restrictions.filter((restriction: any) => restriction.waterRightIds.includes(waterRight.id)).map((restriction: any) => restriction.name);
      const snapshot = snapshots?.find((snapshot: any) => snapshot?.waterRightId === waterRight?.id && snapshot?.year === reportConfig.year);
      const combinedData = { ...waterRight, ...(snapshot || {}) };

      return {
        ...combinedData,
        year: combinedData?.year ?? currentYear,
        wells: tempWellsForWr?.length > 0 ? tempWellsForWr : ["-"],
        fields: tempFieldsForWr.length > 0 ? tempFieldsForWr : ["-"],
        restrictions: restrictionsForWr.length > 0 ? restrictionsForWr : ["-"],
      };
    });
    return data;
  }, [tempWaterRights, wells, fields, restrictions, snapshots]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (reportConfig.waterRightIds !== undefined && reportConfig?.waterRightIds?.length > 0 && wrLookups?.length > 0) {
      setWaterRightIds(reportConfig.waterRightIds);
    } else {
      setWaterRightIds(wrLookups?.map((waterRight: any) => waterRight?.id));
    }
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    if (waterRightIds.length > 0) {
      refreshWaterRightsList();

      if (reportConfig.year < dayjs().year()) {
        refreshSnapshots();
      }
    }
  }, [waterRightIds, reportConfig]);

  useEffect(() => {
    calculateWaterRightColumns();
    // eslint-disable-next-line
  }, [waterRights]);

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      waterRightIds: waterRightIds,
      isActive: reportConfig.active ? reportConfig.active : undefined,
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const refreshSnapshots = async () => {
    setLoadingSnapshots(true);

    const request = {
      companyId: selectedCompanyId,
      year: reportConfig.year,
      waterRightIds: waterRightIds,
    };
    const response = await getWaterRightSnapsotList(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setSnapshots(data.value);
      }
    }

    setLoadingSnapshots(false);
  };

  const calculateWaterRightColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "fileNumber",
        dataIndex: "fileNumber",
        render: (val: any, record: any) => (
          <>
            <div style={{ paddingLeft: 5 }}>{val}</div>
            <div style={{ paddingLeft: 5 }}>PDIV: {record?.pdiv ? record.pdiv : "-"}</div>
            <div style={{ paddingLeft: 5 }}>CIN: {record?.cin ? record?.cin : "-"}</div>
          </>
        ),
      },
      {
        title: "Legal",
        key: "state",
        dataIndex: "state",
        render: (val: any, record: any) =>
          val ? (
            <>
              {record?.section} - {record?.township}
              {record?.townshipDirection} - {record?.range}
              {record?.rangeDirection}
              <br />
              {val ? <LookupLabel showCode={false} lookupType="states" value={val} /> : ""}
            </>
          ) : (
            "-"
          ),
      },
      {
        title: (
          <>
            Priority <br />
            Type
          </>
        ),
        key: "priorityType",
        dataIndex: "priorityType",
        render: (val: any, record: any) => <LookupLabel lookupType="priorityType" value={val} />,
      },
      {
        title: (
          <>
            Permit <br />
            Type
          </>
        ),
        key: "permitType",
        dataIndex: "permitType",
        render: (val: any, record: any) => <LookupLabel lookupType="permitType" value={val} />,
      },
      {
        title: (
          <>
            System <br />
            Type
          </>
        ),
        key: "systemType",
        dataIndex: "systemType",
        render: (val: any, record: any) => <LookupLabel lookupType="systemType" value={val} />,
      },
      {
        title: (
          <>
            Crop <br />
            Type
          </>
        ),
        key: "cropType",
        dataIndex: "cropType",
        render: (val: any, record: any) => <LookupLabel lookupType="cropType" value={val} />,
      },
      {
        title: (
          <>
            End <br />
            Guns
          </>
        ),
        key: "endGuns",
        dataIndex: "endGuns",
        render: (val: any, record: any) => val ?? "-",
      },
      {
        title: (
          <>
            Energy <br />
            Type
          </>
        ),
        key: "energyType",
        dataIndex: "energyType",
        render: (val: any, record: any) => <LookupLabel lookupType="energyType" value={val} />,
      },
      {
        title: "Owner",
        key: "owner",
        dataIndex: "owner",
        render: (val: any, record: any) => <LookupLabel lookupType="owner" value={val} />,
      },
      {
        title: "Water Source",
        key: "waterSource",
        dataIndex: "waterSource",
        render: (val: any, record: any) => <LookupLabel lookupType="waterSource" value={val} />,
      },
      {
        title: "GMD",
        key: "gmd",
        dataIndex: "gmd",
        render: (val: any, record: any) => <LookupLabel lookupType="gmd" value={val} />,
      },
      {
        title: (
          <>
            Subject <br />
            to <br />
            MDS
          </>
        ),
        key: "subjectToMds",
        dataIndex: "subjectToMds",
        render: (val: any, record: any) => <StatusTag status={val ? "Yes" : "No"} color={val ? "blue" : "green"} />,
      },
      {
        title: (
          <>
            Warnings <br />
            Disabled
          </>
        ),
        key: "disableWarnings",
        dataIndex: "disableWarnings",
        render: (val: any, record: any) =>
          val ? (
            <>
              <StatusTag status={"Yes"} />
              <br />
            </>
          ) : (
            <StatusTag status={"No"} />
          ),
      },
      {
        title: (
          <>
            Authorized
            <br />
            <small>Irrigation Acres</small>
            <br />
            <small>Quantity (Acre Feet)</small>
            <br />
            <small>Rate (GPM)</small>
          </>
        ),
        key: "authorized",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === record?.year)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? "N/A" : <UnitsConverter fromUnits={record?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} />}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate}</div>}
              </>
            );
          else if (record?.year < currentYear && record?.referenceYear)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? "N/A" : <UnitsConverter fromUnits={record?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} />}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate}</div>}
              </>
            );
          else
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : `${record?.authorizedIrrigationAcres} *`}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    <UnitsConverter fromUnits={record?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} /> *
                  </>
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate} *</div>}
              </>
            );
        },
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>Irrigation Acres</small>
            <br />
            <small>Quantity (Acre Feet)</small>
            <br />
            <small>Rate (GPM)</small>
          </>
        ),
        key: "usage",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === record?.year)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ?? record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter
                    fromUnits={record?.usageQuantity ? record?.usageQuantityUnits : record?.authorizedQuantityUnits}
                    toUnits="acrefeet"
                    value={record?.usageQuantity ?? record?.authorizedQuantity}
                  />
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ?? record?.authorizedRate}</div>}
              </>
            );
          else if (record?.year < currentYear && record?.referenceYear)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ?? record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter
                    fromUnits={record?.usageQuantity ? record?.usageQuantityUnits : record?.authorizedQuantityUnits}
                    toUnits="acrefeet"
                    value={record?.usageQuantity ?? record?.authorizedQuantity}
                  />
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ?? record?.authorizedRate}</div>}
              </>
            );
          else
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ? `${record?.usageIrrigationAcres} *` : `${record?.authorizedIrrigationAcres} *`}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    <UnitsConverter
                      fromUnits={record?.usageQuantity ? record?.usageQuantityUnits : record?.authorizedQuantityUnits}
                      toUnits="acrefeet"
                      value={record?.usageQuantity ?? record?.authorizedQuantity}
                    />{" "}
                    *
                  </>
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ? `${record?.usageRate} *` : `${record?.authorizedRate} *`}</div>}
              </>
            );
        },
      },
    ];

    setWaterRightColumns(tempColumns);
  };

  const renderExpandData = (record: any) => {
    return (
      <>
        <div
          style={{
            padding: 0,
            margin: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <StatusTag style={{ margin: 2, maxWidth: 200 }} status={record?.status} />
          <WaterRightTagList internalTags={record.internalTags} externalTags={record.externalTags} style={{ marginLeft: 0 }} />
        </div>
        <div style={{ padding: 5 }}>
          {record?.wells?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 100,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Wells / Meters:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.wells?.map((x: any) => x).join(", ")}</div>
            </div>
          )}
          {record?.restrictions?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 100,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Restrictions:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.restrictions?.map((x: any) => x).join(", ")}</div>
            </div>
          )}
          {record?.restrictions?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 100,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Fields:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.fields?.map((x: any) => x).join(", ")}</div>
            </div>
          )}
          {!selectedCompanyId && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 100,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Company:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>
                <CompanyLabel companyId={record?.companyId} />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Water Right Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          // title={`Summary Report on ${reportConfig.generatedDate}`}
          loading={loadingWaterRights}
        >
          {
            <Card title="Water Rights">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingWaterRights || loadingSnapshots}
                  columns={waterRightColumns}
                  dataSource={waterRights ?? []}
                  size="small"
                  pagination={false}
                  expandable={{
                    showExpandColumn: false,
                    defaultExpandAllRows: true,
                    expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                    expandedRowRender: (record: any) => renderExpandData(record),
                  }}
                  footer={() => {
                    if (reportConfig?.year < currentYear)
                      return (
                        <Alert
                          description={`The Authorized and Usage columns may contain "*" alongside the values if a snapshot is configured for the water right for any year range that is not the current year (${currentYear})`}
                          type="warning"
                          showIcon
                        />
                      );
                    else return undefined;
                  }}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Water Rights">{reportConfig.waterRights === "" ? "-" : reportConfig.waterRights}</Descriptions.Item>
                <Descriptions.Item label="Permit Types">{reportConfig?.permitTypes === "" ? "-" : reportConfig?.permitTypes}</Descriptions.Item>
                <Descriptions.Item label="Priority Types">{reportConfig?.priorityTypes === "" ? "-" : reportConfig?.priorityTypes}</Descriptions.Item>
                <Descriptions.Item label="Year">{reportConfig.year}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
