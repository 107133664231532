import {
  BellOutlined,
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
  ContactsOutlined,
  CrownOutlined,
  DashboardOutlined,
  DollarOutlined,
  FileTextOutlined,
  HomeOutlined,
  MenuOutlined,
  NotificationOutlined,
  PlusOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  ShopOutlined,
  StarOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { Layout, theme } from "antd";
import { routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import React, { useEffect, useState } from "react";
import { MdSupportAgent } from "react-icons/md";
import { useSelector } from "react-redux";
import useCustomNavigate from "@/services/useCustomNavigate";
import { showNavigationWarning } from "@/services/utils";
import { RootState, useAppDispatch } from "@/stores";
import { addBreadcrumb, clearBreadcrumbs } from "@/stores/breadcrumbs.store";
import MenuItem from "./MenuItem";
import useUnreadNotificationCount from "@/queries/useUnreadNotificationCount";
import useActionsCounter from "@/queries/useActionsCounter";
import useOutstandingCompanyCounts from "@/queries/useOutstandingCompanyCounts";
import useRoleBasedFeature, { RoleBasedFeature } from "@/services/useRoleBasedFeature";

const { Sider } = Layout;

const CustomSider: React.FC = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigate();

  const { selectedCompany, canSwitch, busyEditing, selectedCompanyId } = useSelector((state: RootState) => state.company);
  const { selectedRole } = useSelector((state: any) => state.user);

  const [collapsed, setCollapsed] = React.useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([]);

  const { count: unreadNotificationsCount } = useUnreadNotificationCount();
  const { count: outstandingCompanyCounts } = useOutstandingCompanyCounts();
  const { actionsCount } = useActionsCounter();

  const hasAccessToFeature = useRoleBasedFeature();

  useEffect(() => {
    selectedRole ? determineRoleBasedMenuItems() : determineMenuItems();
  }, [selectedCompany, canSwitch, busyEditing, selectedCompanyId, unreadNotificationsCount, actionsCount, outstandingCompanyCounts, hasAccessToFeature]);

  const determineRoleBasedMenuItems = () => {
    let tempMenuItems: any[] = [];

    tempMenuItems.push(
      hasAccessToFeature?.[RoleBasedFeature.Dashboard] && {
        label: "Dashboard",
        key: "dashboard",
        icon: <DashboardOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          showNavigationWarning(busyEditing, () => navigate(routes.adminDashboard));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.Communications] && {
        label: "Communication",
        key: "communication",
        icon: <BellOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Communication`,
              url: `communication`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.adminCommunication));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.Billing] && {
        label: "Billing",
        key: "billing",
        icon: <DollarOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Billing`,
              url: `billing`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.adminBilling));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.Events] && {
        label: "Events",
        key: "events",
        icon: <CalendarOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Events`,
              url: `event`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.adminEvents));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.Users] && {
        label: "Users",
        key: "users",
        icon: <ProfileOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Users`,
              url: `users`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.adminUsers));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.Reports] && {
        label: "Reports",
        key: "reports",
        icon: <FileTextOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Reports`,
              url: `reports`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.adminReports));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.FAQ] && {
        label: "FAQ",
        key: "faqs",
        icon: <QuestionCircleOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `FAQ`,
              url: `faqs`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.adminFaqsList));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.ContactMessages] && {
        label: "Contact Messages",
        key: "contactUsList",
        icon: <ContactsOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Contact Messages`,
              url: `contactus/list`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.adminContactUsList));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.SupportQueries] && {
        label: "Support Queries",
        key: "supportQueries",
        icon: <MdSupportAgent style={{ verticalAlign: "center" }} />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Support Queries`,
              url: `supportquery/list`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.adminSupportQueryList));
        },
      },
      hasAccessToFeature?.[RoleBasedFeature.Lookups] && {
        label: "Lookups",
        key: "lookups",
        icon: <SearchOutlined />,
        disabled: false,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Lookups`,
              url: `lookups`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.lookups));
        },
      }
    );

    setMenuItems(tempMenuItems.filter((x) => x));
  };

  const determineMenuItems = () => {
    let tempMenuItems: any[] = [];

    tempMenuItems.push({
      label: "Dashboard",
      key: "dashboard",
      icon: <DashboardOutlined />,
      disabled: false,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        showNavigationWarning(busyEditing, () => navigate(routes.dashboard));
      },
      count: actionsCount + (outstandingCompanyCounts === undefined ? 0 : outstandingCompanyCounts),
      // count: actionsCount +  outstandingCompanyCounts ? outstandingCompanyCounts : 0,
      countColor: "red",
    });

    tempMenuItems.push({
      label: "Notifications",
      key: "notifications",
      icon: <NotificationOutlined />,
      disabled: false,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Notifications`,
            url: `notificaiton`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.notification));
      },
      count: unreadNotificationsCount,
      countColor: "red",
    });

    tempMenuItems.push({
      label: "Companies",
      key: "company",
      icon: <CrownOutlined />,
      disabled: false,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Companies`,
            url: `company/list`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.companyList));
      },
      children: [
        {
          label: "Add Company",
          key: "companyAdd",
          icon: <PlusOutlined />,
          disabled: false,
          onClick: () => {
            dispatch(clearBreadcrumbs());
            dispatch(
              addBreadcrumb({
                type: `Company / Add`,
                url: `company/add`,
              })
            );
            showNavigationWarning(busyEditing, () => navigate(routes.companyAdd));
          },
        },
      ],
    });

    tempMenuItems.push({
      label: "Fields",
      key: "field",
      icon: <StarOutlined />,
      disabled: false,
      requiresSelectedCompany: true,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Fields`,
            url: `field/list`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.fieldList));
      },
      children: [
        {
          label: "Add Field",
          key: "fieldAdd",
          icon: <PlusOutlined />,
          disabled: false,
          onClick: () => {
            dispatch(clearBreadcrumbs());
            dispatch(
              addBreadcrumb({
                type: `Field / Add`,
                url: `field/add`,
              })
            );
            showNavigationWarning(busyEditing, () => navigate(routes.fieldAdd));
          },
        },
      ],
    });

    tempMenuItems.push({
      label: "Water Rights",
      key: "waterRight",
      icon: <CheckOutlined />,
      disabled: false,
      requiresSelectedCompany: true,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Water Rights`,
            url: `waterright/list`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.waterRightList));
      },
      children: [
        {
          label: "Add Water Right",
          key: "waterRightAdd",
          icon: <PlusOutlined />,
          disabled: false,
          onClick: () => {
            dispatch(clearBreadcrumbs());
            dispatch(
              addBreadcrumb({
                type: `Water Right / Add`,
                url: `waterright/add`,
              })
            );
            showNavigationWarning(busyEditing, () => navigate(routes.waterRightAdd));
          },
        },
      ],
    });

    tempMenuItems.push({
      label: "Wells / Meters",
      key: "well",
      icon: <MenuOutlined />,
      disabled: false,
      requiresSelectedCompany: true,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Wells`,
            url: `well/list`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.wellList));
      },
      children: [
        {
          label: "Add Well / Meter",
          key: "wellAdd",
          icon: <PlusOutlined />,
          disabled: false,
          onClick: () => {
            dispatch(clearBreadcrumbs());
            dispatch(
              addBreadcrumb({
                type: `Well / Add`,
                url: `well/add`,
              })
            );
            showNavigationWarning(busyEditing, () => navigate(routes.wellAdd));
          },
        },
      ],
    });

    tempMenuItems.push({
      label: "Restrictions",
      key: "restrictions",
      icon: <CloseOutlined />,
      disabled: false,
      requiresSelectedCompany: true,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Restrictions`,
            url: `restriction/list`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.restrictionList));
      },
      children: [
        {
          label: "Add Restriction",
          key: "restrictionAdd",
          icon: <PlusOutlined />,
          disabled: false,
          onClick: () => {
            dispatch(clearBreadcrumbs());
            dispatch(
              addBreadcrumb({
                type: `Restriction / Add`,
                url: `restriction/add`,
              })
            );
            showNavigationWarning(busyEditing, () => navigate(routes.restrictionAdd));
          },
        },
      ],
    });

    if (selectedCompany?.userRole === UserRole.admin.label || !selectedCompanyId)
      tempMenuItems.push({
        label: "Groupings",
        key: "grouping",
        icon: <ShopOutlined />,
        disabled: false,
        requiresSelectedCompany: true,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Groupings`,
              url: `grouping/list`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.groupingList));
        },
        children: [
          {
            label: "Add Grouping",
            key: "groupingAdd",
            icon: <PlusOutlined />,
            disabled: false,
            onClick: () => {
              dispatch(clearBreadcrumbs());
              dispatch(
                addBreadcrumb({
                  type: `Grouping / Add`,
                  url: `grouping/add`,
                })
              );
              showNavigationWarning(busyEditing, () => navigate(routes.groupingAdd));
            },
          },
        ],
      });

    tempMenuItems.push({
      label: "Stock",
      key: "stock",
      icon: <TagsOutlined />,
      disabled: false,
      requiresSelectedCompany: true,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Stock`,
            url: `stock/list`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.stockList));
      },
      children: [
        {
          label: "Add Stock",
          key: "stockAdd",
          icon: <PlusOutlined />,
          disabled: false,
          onClick: () => {
            dispatch(clearBreadcrumbs());
            dispatch(
              addBreadcrumb({
                type: `Stock / Add`,
                url: `stock/add`,
              })
            );
            showNavigationWarning(busyEditing, () => navigate(routes.stockAdd));
          },
        },
      ],
    });

    tempMenuItems.push({
      label: "Places of Use",
      key: "placesOfUse",
      icon: <HomeOutlined />,
      disabled: false,
      requiresSelectedCompany: true,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Places of Use`,
            url: `placesofuse/list`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.placeOfUseList));
      },
      // children: [
      //   {
      //     label: 'Add Place of Use',
      //     key: 'placeOfUseAdd',
      //     icon: <PlusOutlined />,
      //     onClick: () => {
      //       dispatch(clearBreadcrumbs())
      //       dispatch(addBreadcrumb({
      //         type: `Place of Use / Add`,
      //         url: `placeofuse/add`
      //       }))
      //       showNavigationWarning(busyEditing, () => navigate(routes.placeOfUseAdd))
      //     }
      //   }
      // ]
    });

    if (selectedCompany?.userRole === UserRole.admin.label)
      tempMenuItems.push({
        label: "Users",
        key: "user",
        icon: <ProfileOutlined />,
        disabled: false,
        requiresSelectedCompany: true,
        onClick: () => {
          dispatch(clearBreadcrumbs());
          dispatch(
            addBreadcrumb({
              type: `Users`,
              url: `user/list`,
            })
          );
          showNavigationWarning(busyEditing, () => navigate(routes.userList));
        },
        children: [
          {
            label: "Add User",
            key: "userAdd",
            icon: <PlusOutlined />,
            onClick: () => {
              dispatch(clearBreadcrumbs());
              dispatch(
                addBreadcrumb({
                  type: `User / Add`,
                  url: `user/add`,
                })
              );
              showNavigationWarning(busyEditing, () => navigate(routes.userAdd));
            },
          },
        ],
      });

    tempMenuItems.push({
      label: "Reports",
      key: "reports",
      icon: <FileTextOutlined />,
      requiresSelectedCompany: true,
      disabled: false,
      onClick: () => {
        dispatch(clearBreadcrumbs());
        dispatch(
          addBreadcrumb({
            type: `Reports`,
            url: `report/list`,
          })
        );
        showNavigationWarning(busyEditing, () => navigate(routes.reportList));
      },
    });

    setMenuItems(tempMenuItems);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Sider
      id="globalSider"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        background: colorBgContainer,
        minHeight: "calc(100vh - 94px)",
        boxShadow: "0px 0px 5px 0px #bcbbbb",
      }}
      collapsedWidth={58}
      width={200}
      theme={"light"}
    >
      {menuItems
        .filter((x: any) => (x.position === "top" || x.position === undefined) && ((selectedCompany && x.requiresSelectedCompany) || !x.requiresSelectedCompany))
        .map((item: any) => MenuItem(collapsed, item, undefined, item.position, item.count, item.countColor))}
      <div
        style={{
          position: "absolute",
          bottom: 53,
          left: 0,
          width: "100%",
        }}
      >
        {menuItems
          .filter((x: any) => x.position === "bottom" && ((selectedCompany && x.requiresSelectedCompany) || !x.requiresSelectedCompany))
          .map((item: any) => MenuItem(collapsed, item, undefined, item.position, item.count, item.countColor))}
      </div>
    </Sider>
  );
};

export default CustomSider;
