import { Button, Input, message, Popconfirm, Space, Switch, Table } from "antd";
import { deleteCompanyInvitedUser, getUsersForCompany, resendUserInvite } from "@/apis/company.api";
import { AuditLogTable, StatusTag } from "@/components";
import { routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/stores";
import { clearUserStateExcludingProfile } from "@/stores/user.store";
import { ReloadOutlined } from "@ant-design/icons";

import "./UsersTable.scss";
import { merge } from "lodash";
import useCustomNavigate from "@/services/useCustomNavigate";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";

export interface UsersTableProps {
  companyId?: string;
  applyPaddingOnSearch?: boolean;
  trackTableState?: boolean;
}

const UsersTable: FC<UsersTableProps> = (props: UsersTableProps) => {
  const dispatch = useAppDispatch();
  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const { companyId, applyPaddingOnSearch = false, trackTableState = false } = props;

  const { selectedCompany } = useSelector((state: any) => state.company);

  const [userColumns, setUserColumns] = useState<any[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [isActive, setIsActive] = useState<string | undefined>("inactive");

  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    return () => {
      dispatch(clearUserStateExcludingProfile());
    };
  }, []);

  useEffect(() => {
    if (companyId || selectedCompany) {
      refreshUsersList();
    }
  }, [companyId, selectedCompany, isActive]);

  useEffect(() => {
    if (users) calculateUsersColumns();
  }, [users]);

  const refreshUsersList = async (searchString: any = null) => {
    setLoadingUsers(true);

    const request = {
      companyId: companyId ?? selectedCompany.companyId,
      searchString: searchString,
      isActive: isActive,
    };

    const response = await getUsersForCompany(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setUsers(data.value);
      }
    }
    setLoadingUsers(false);
  };

  const handleDeleteInvitedUser = async (id: any) => {
    const response = await deleteCompanyInvitedUser(id);

    if (response.ok) {
      message.success("Deleted invited user successfully.");
      refreshUsersList();
    } else {
      message.error("Failed to delete the invited user.");
    }
  };

  const calculateUsersColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        fixed: "left",
        render: (val: any, record: any) =>
          selectedCompany?.userRole === UserRole.admin.label ? (
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleEdit(record)}>
              {val}
            </Button>
          ) : (
            val
          ),
      },
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        render: (val: any, record: any) => (record.type === "user" ? record.name : "N/A"),
      },
      {
        title: "Role",
        key: "role",
        dataIndex: "role",
        render: (val: any) => UserRole[val]?.label,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} date={record.date} />,
      },
    ];

    if (selectedCompany?.userRole === UserRole.admin.label)
      tempColumns.push({
        title: "Actions",
        key: "action",
        width: 300,
        render: (value: any, record: any) => (
          <>
            {selectedCompany?.userRole === UserRole.admin.label && (
              <Button type="link" onClick={() => handleEdit(record)} style={{ paddingLeft: 0 }}>
                Edit
              </Button>
            )}

            {record.type === "invite" && (
              <>
                {" | "}
                {selectedCompany?.userRole === UserRole.admin.label && (
                  <Popconfirm
                    title={`Delete invited user`}
                    description={`Are you sure you want to permanently delete the invited user? This cannot be reversed.`}
                    // description="Are you sure you want to validate this well reading?"
                    onConfirm={() => handleDeleteInvitedUser(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button style={{ paddingRight: 10 }} type="link">
                      Delete
                    </Button>
                  </Popconfirm>
                )}
                {selectedCompany?.userRole === UserRole.admin.label && ` | `}
                {/* {selectedCompany?.userRole === UserRole.admin.label && <Button type='link' onClick={() => handleResendInvite(record)}>
                        Delete
                    </Button>}
                    {selectedCompany?.userRole === UserRole.admin.label && ` | `} */}
                <Button type="link" onClick={() => handleResendInvite(record)}>
                  Resend Invite
                </Button>
              </>
            )}
          </>
        ),
      });

    setUserColumns(tempColumns);
  };

  const handleEdit = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `user/${record?.id}/edit?type=${record?.type}`,
      })
    );
    navigate(routes.userEdit, { id: record.id }, { type: record.type });
  };

  const handleResendInvite = async (record: any) => {
    const response = await resendUserInvite(record.id);
    if (response.ok) {
      message.success("Resent user invite successfully");
      navigate(routes.userList);
    } else {
      message.error("Failed to resent the user invite");
    }
  };

  const handleRefresh = () => {
    setSearchString(undefined);
    refreshUsersList();
  };

  const onSearchUsersChange = (searchString: any) => {
    setSearchString(searchString);
  };

  const onSearchUsers = (searchString: any) => {
    setSearchString(searchString);
    refreshUsersList(searchString);
  };

  const renderExpandData = (record: any) => {
    // TODO: Fix audit log for users
    return <AuditLogTable entityId={record.id} entityType={AuditLogType.User} />;
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(undefined);
    else setIsActive("inactive");
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Space style={merge({ paddingBottom: 10 }, applyPaddingOnSearch ? { paddingLeft: 50 } : undefined)}>
        <Input.Search
          disabled={loadingUsers}
          placeholder="Search users"
          onSearch={(searchString) => onSearchUsers(searchString)}
          onChange={(val) => onSearchUsersChange(val.currentTarget.value)}
          value={searchString}
          style={{ width: 400 }}
          allowClear
        />
        <Button disabled={loadingUsers} icon={<ReloadOutlined />} onClick={handleRefresh}>
          Refresh
        </Button>
        <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
      </Space>

      <Table
        pagination={
          trackTableState
            ? {
                pageSize: queryParams?.pageSize ? Number(queryParams?.pageSize) : 10,
                current: queryParams?.page ? Number(queryParams?.page) : 1,
              }
            : undefined
        }
        onChange={(pagination, filters, sorter) => {
          trackTableState &&
            updateQueryParams({
              page: pagination.current,
              pageSize: pagination.pageSize,
            });
        }}
        rowKey={(row: any) => row.id}
        expandable={
          selectedCompany?.userRole === UserRole.admin.label
            ? {
                expandedRowRender: (record: any) => renderExpandData(record),
              }
            : undefined
        }
        loading={loadingUsers}
        columns={userColumns}
        dataSource={users || []}
        size="small"
      />
    </Space>
  );
};

export default UsersTable;
