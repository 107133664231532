import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import { convertUnits, formatMeterUnits } from "@/services/utils";
import { Card, ConfigProvider, Empty, Table } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";

type Props = {
  companyTotalUsage: any[];
  companyId: string;
  loading: boolean;
};

const backgroundColor = ["rgb(128,128,128)", "rgba(2,3,129)"];

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const CompanyUsageRender: FC<Props> = ({ companyTotalUsage, companyId, loading }) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<any>({
    plugins: {
      legend: {
        position: "bottom",
        padding: 20,
        align: "center",
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
        },
      },
      y: {
        title: {
          display: true,
          text: "Gallons",
        },
      },
    },
  });

  const { companyConfigs } = useComapnyConfigLookup();

  const stackedBarData: any | undefined = useMemo(() => {
    const companyConfig = companyConfigs?.find((company: any) => company?.id === companyId);
    const meterUnits = formatMeterUnits(companyConfig?.settings?.metric);

    setChartOptions((prevValue: any) => {
      return {
        ...prevValue,
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: "Year",
            },
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: meterUnits,
              padding: 25,
            },
          },
        },
      };
    });

    const labels = companyTotalUsage?.map((usage: any) => usage?.year);
    const dataLabels = ["Total Authorized Quantity", "Total Usage"];
    let barData: any[] = [];

    for (let index = 0; index < 2; index++) {
      if (index === 0) {
        barData.push({
          label: dataLabels[index],
          data: companyTotalUsage?.map((usage: any) => convertUnits(usage?.totalAvailableQuantity, "gallons", companyConfig?.settings?.metric, undefined)),
          backgroundColor: backgroundColor[index],
          stack: "Stack 1",
        });
      }

      if (index === 1) {
        barData.push({
          label: dataLabels[index],
          data: companyTotalUsage?.map((usage: any) => convertUnits(usage?.totalUsage, "gallons", companyConfig?.settings?.metric, undefined)),
          backgroundColor: backgroundColor[index],
          stack: "Stack 0",
        });
      }
    }

    const dataSets = {
      labels: labels,
      datasets: barData,
    };

    return dataSets;
  }, [companyTotalUsage, companyConfigs]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [companyTotalUsage]);

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Year",
        key: "year",
        dataIndex: "year",
        width: 300,
        render: (val: any) => val ?? "-",
      },
      {
        title: (
          <>
            Total Authorized Quantity
            <br />
            <small>Gallons</small>
            <br />
            <small>Acre Feet</small>
          </>
        ),
        key: "totalAvailableQuantity",
        dataIndex: "totalAvailableQuantity",
        align: "right",
        render: (val: any) => (
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} overrideDecimalPlaces={2} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={val} overrideDecimalPlaces={2} />
          </div>
        ),
      },
      {
        title: (
          <>
            Total Usage
            <br />
            <small>Gallons</small>
            <br />
            <small>Acre Feet</small>
          </>
        ),
        key: "totalUsage",
        dataIndex: "totalUsage",
        align: "right",
        render: (val: any) => (
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} overrideDecimalPlaces={2} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={val} overrideDecimalPlaces={2} />
          </div>
        ),
      },
      {
        title: "Percentage Remaining",
        width: 300,
        align: "right",
        render: (val: any, record: any) => {
          const percentage = calculatePercentage(record?.totalUsage, record?.totalAvailableQuantity);
          if (percentage === 0) return <>0.00 %</>;
          else return <>{percentage} %</>;
        },
      },
    ];

    setColumns(tempColumns);
  };

  const calculatePercentage = (totalUsage: number, totalAvailableQuantity: number) => {
    if (totalUsage > totalAvailableQuantity) return 0;
    else return ((1 - totalUsage / totalAvailableQuantity) * 100).toFixed(2);
  };

  return stackedBarData ? (
    <>
      <Card title={`${companyConfigs?.find((company: any) => company?.id === companyId)?.name} - Company Total Usage`}>
        <Bar
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 1000,
            maxHeight: 800,
            marginLeft: "auto",
            marginRight: "auto",
          }}
          options={chartOptions}
          data={stackedBarData}
        />
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
            rowKey={(row: any) => row.companyId}
            loading={loading}
            columns={columns}
            dataSource={companyTotalUsage ?? []}
            size="small"
            pagination={false}
          />
        </ConfigProvider>
      </Card>
      {/* <div className='page-break' /> */}
    </>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};

export default CompanyUsageRender;
