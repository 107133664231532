import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Space } from "antd";
import { UsersTable } from "@/components";
import { routes } from "@/configs";
import { FC } from "react";
import PrintPage from "@/components/PrintPage/PrintPage";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import "./UserList.scss";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";

const UserList: FC = () => {
  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();

  const handleAddUser = () => {
    dispatch(
      addBreadcrumb({
        type: `User / Add`,
        url: `user/add`,
      })
    );
    navigate(routes.userAdd);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Card
        className="userList"
        id="userList"
        title="Users"
        extra={
          <>
            <Button onClick={handleAddUser} type="primary" icon={<PlusOutlined />}>
              Add User
            </Button>
          </>
        }
      >
        <PrintPage
          content={
            <div>
              <UsersTable trackTableState />
            </div>
          }
        />
      </Card>
    </Space>
  );
};

export default UserList;
