import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Popconfirm, Popover, message } from "antd";
import { manualRenewRestriction } from "@/apis/restriction.api";
import { LookupLabel } from "@/components";
import RemainingYearlyAverageTable from "@/components/RemainingYearlyAverageTable/RemainingYearlyAverageTable";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { constants, routes } from "@/configs";
import dayjs from "dayjs";
import { UserRole } from "@/dtos/user.dto";
import { FC, useState } from "react";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";

interface Props {
  selectedCompany: any;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  remainingYears: any;
  calculation: any;
  isReport?: boolean;
  restriction?: any;
  restrictionTable?: boolean;
  type?: any;
  handleRefresh?: () => void;
  companyId: string;
}

const RestrictionPopover: FC<Props> = (props) => {
  const { selectedCompany, companyId, startDate, endDate, remainingYears, calculation, isReport = false, restriction = null, restrictionTable = false, type = null, handleRefresh } = props;

  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigate();

  const { canEdit } = useSelectedCompanyData(companyId);

  const [loadingRenewRestriction, setLoadingRenewRestriction] = useState<boolean>(false);

  const currentYear = dayjs().year();

  const handleRenewRestriction = async (record: any) => {
    setLoadingRenewRestriction(true);

    const response = await manualRenewRestriction(record.id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        message.success(`Restriction ${record.name} has been manually renewed`);
        handleRefresh && handleRefresh();
      } else {
        message.error(`Failed to manually renew restriciton ${record.name}`);
      }
    }

    setLoadingRenewRestriction(false);
  };

  const handleViewRestriction = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `restriction/${record.id}/view`,
      })
    );
    navigate(routes.restrictionView, { id: record.id });
  };

  const renderItem = (label: string, value: any) => (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: 120 }}>{label}:</div>
      <div>{value}</div>
    </div>
  );

  return (
    <div style={{ paddingRight: 10, marginBottom: undefined }}>
      <Popover
        placement="topRight"
        align={{ offset: [14, -10] }}
        style={{ maxWidth: "400px" }}
        content={
          <>
            <div style={{ display: "flex", flexDirection: "row", gap: 40, marginBottom: 10 }}>
              <div>
                {renderItem("Start Date", startDate.format(constants.dateFormat))}
                {renderItem("End Date", endDate.format(constants.dateFormat))}
                {!restrictionTable && renderItem("Type", <LookupLabel lookupType="restrictiontype" value={type} />)}
              </div>
              <div>
                {renderItem("Term", calculation?.years ?? "-")}
                {renderItem("Current Year", remainingYears > 0 ? (calculation?.years ?? 0) - (remainingYears ?? 0) + 1 : "-")}
              </div>
              <div>
                {renderItem("Term Quantity", <UnitsConverter fromUnits={"gallons"} toUnits="acrefeet" value={calculation?.termQuantity} showUnitsLabel />)}
                {renderItem("Baseline Quantity", <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={calculation?.baselineQuantity} showUnitsLabel />)}
              </div>
            </div>
            <RemainingYearlyAverageTable calculation={calculation} />
            {restrictionTable && canEdit && dayjs(restriction?.endDate).year() < currentYear && restriction?.renewedRestrictionId === null && (
              <>
                <Divider style={{ marginTop: 15, marginBottom: 5 }} />
                <Popconfirm
                  title={"Renew Restriction"}
                  description="Are you sure you wish to renew this restriction?"
                  okText="Yes"
                  cancelText="Cancel"
                  onConfirm={() => handleRenewRestriction(restriction)}
                >
                  <Button loading={loadingRenewRestriction} type="primary">
                    Renew
                  </Button>
                </Popconfirm>
              </>
            )}
            {!restrictionTable && !isReport && (
              <>
                <Divider style={{ marginTop: 15, marginBottom: 5 }} />
                <Popconfirm
                  title={"View Restriction"}
                  description="Are you sure you wish to navigate to this restriction?"
                  okText="Yes"
                  cancelText="Cancel"
                  onConfirm={() => handleViewRestriction(calculation)}
                >
                  <Button type="primary">View</Button>
                </Popconfirm>
              </>
            )}
          </>
        }
        title={restriction?.name ?? calculation.name}
      >
        {restrictionTable ? (
          <InfoCircleOutlined
            style={{
              color: endDate.year() < currentYear && restriction?.renewedRestrictionId === null ? "red" : "black",
            }}
          />
        ) : (
          <InfoCircleOutlined />
        )}
      </Popover>
    </div>
  );
};

export default RestrictionPopover;
