import { getFields } from "@/apis/field.api";
import { getRestrictions, syncRestrictionCalculations } from "@/apis/restriction.api";
import { getWaterRightSnapsotList, getWaterRights, syncWaterRightCalculation } from "@/apis/waterright.api";
import { getWells } from "@/apis/well.api";
import { LookupLabel } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { constants } from "@/configs";
import { convertUnits } from "@/services/utils";
import { Alert, Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedSummaryUsageReport.scss";
import useWaterRightLookups from "@/queries/useWaterRightLookups";

const { Meta } = Card;

interface Props {
  reportConfig: {
    selectedWaterRightNames?: string;
    waterRightIds?: string[];
    waterRights?: string;
    selectedRestrictionNames?: string;
    restrictionIds?: string[];
    restrictions?: string;
    permitTypes?: string;
    priorityTypes?: string;
    generatedDate: string;
    year: number;
  };
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedSummaryUsageReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [loadingRestrictions, setLoadingRestrictions] = useState<boolean>(false);
  const [loadingCalculations, setLoadingCalculations] = useState<boolean>(false);
  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const [loadingSnapshots, setLoadingSnapshots] = useState<boolean>(false);

  const [tempWaterRights, setWaterRights] = useState<any[]>([]);
  const [restrictions, setRestrictions] = useState<any[]>([]);
  const [restrictionCalculations, setRestrictionCalculations] = useState<any[]>([]);
  const [loadingRestrictionCalculations, setLoadingRestrictionCalculations] = useState<boolean>(false);
  const [calculations, setCalculations] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [snapshots, setSnapshots] = useState<any[]>([]);

  const [waterRightIds, setWaterRightIds] = useState<string[]>([]);

  const [waterRightColumns, setWaterRightColumns] = useState<any[]>([]);
  const [restrictionColumns, setRestrictionColumns] = useState<any[]>([]);

  const { waterRights: wrLookups } = useWaterRightLookups();

  const currentYear = dayjs().year();

  const waterRights: any[] = useMemo(() => {
    const data = tempWaterRights.map((waterRight: any) => {
      const wellsForWaterRight = wells?.filter((well) => well?.waterRightId === waterRight?.id);
      const fieldsForWaterRight = wellsForWaterRight.map((well) => well.fieldId);
      const tempWells = wellsForWaterRight.map((well) => well.name);
      const tempFields = fields?.filter((field) => fieldsForWaterRight?.includes(field?.id))?.map((field) => field?.name);

      const calculation = calculations.find((calculation) => calculation.waterRightId === waterRight.id && reportConfig?.year === calculation.year);

      const snapshot = snapshots?.find((snapshot) => snapshot.waterRightId === waterRight.id);

      if (snapshot) {
        const { id, ...rest } = snapshot;
        return {
          ...waterRight,
          ...(rest || {}),
          ...(calculation || {}),
          wells: tempWells?.length > 0 ? tempWells : ["-"],
          fields: tempFields.length > 0 ? tempFields : ["-"],
        };
      }

      return {
        ...waterRight,
        ...(calculation || {}),
        wells: tempWells?.length > 0 ? tempWells : ["-"],
        fields: tempFields.length > 0 ? tempFields : ["-"],
      };
    });
    return data;
    // eslint-disable-next-line
  }, [tempWaterRights, wells, calculations, fields, snapshots]);

  useEffect(() => {
    if (reportConfig) {
      refreshCalculations();
      refreshRestrictionCalculations();
      refreshWaterRightsList();
      refreshRestrictionsList();
      refreshWells();
      refreshFields();
    }
    // eslint-disable-next-line
  }, [reportConfig && waterRightIds]);

  useEffect(() => {
    if (reportConfig?.waterRightIds !== undefined && reportConfig.waterRightIds.length > 0) {
      setWaterRightIds(reportConfig.waterRightIds);
    } else {
      setWaterRightIds(wrLookups?.map((wr: any) => wr?.id));
    }
  }, [reportConfig]);

  useEffect(() => {
    if (reportConfig?.year < currentYear) refreshSnapshots();
  }, [reportConfig]);

  useEffect(() => {
    calculateWaterRightColumns();
    // eslint-disable-next-line
  }, [waterRights, calculations, restrictions, restrictionCalculations]);

  useEffect(() => {
    calculateRestrictionColumns();
    // eslint-disable-next-line
  }, [restrictions, restrictionCalculations]);

  const refreshSnapshots = async () => {
    setLoadingSnapshots(true);

    const request = {
      year: reportConfig?.year,
      waterRightIds: waterRightIds,
    };
    const response = await getWaterRightSnapsotList(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setSnapshots(data.value);
      }
    }

    setLoadingSnapshots(false);
  };

  const refreshCalculations = async () => {
    setLoadingCalculations(true);
    const response = await syncWaterRightCalculation({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCalculations(data.value);
      }
    }
    setLoadingCalculations(false);
  };

  const refreshRestrictionCalculations = async () => {
    setLoadingRestrictionCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictionCalculations(data.value);
      }
    }
    setLoadingRestrictionCalculations(false);
  };

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: undefined,
      waterRightIds: waterRightIds,
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const refreshRestrictionsList = async () => {
    setLoadingRestrictions(true);

    const request = {
      companyId: selectedCompanyId,
    };

    const response = await getRestrictions(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictions(data.value);
      }
    }
    setLoadingRestrictions(false);
  };

  const filteredRestrictionList = () => {
    return restrictions.filter(
      (x: any) =>
        (reportConfig?.restrictionIds?.includes(x.id) || x.waterRightIds.some((y: any) => waterRightIds?.includes(y))) &&
        dayjs(x.startDate).year() <= reportConfig.year &&
        dayjs(x.endDate).year() >= reportConfig.year
    );
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    const request = { companyId: selectedCompanyId };
    const response = await getWells(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingWells(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);
    const request = { companyId: selectedCompanyId };
    const response = await getFields(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFields(data.value);
      }
    }
    setLoadingFields(false);
  };

  const determineValue = (
    id: string,
    type: string,
    postfix: any = undefined,
    irrigationAcresNotApplicable: boolean | undefined = undefined,
    quantityNotApplicable: boolean | undefined = undefined,
    rateNotApplicable: boolean | undefined = undefined
  ) => {
    const existingCalculation = calculations.find((calculation) => calculation.waterRightId === id && reportConfig?.year === calculation.year);
    if (existingCalculation) {
      if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (existingCalculation.quantityNotApplicable === true || existingCalculation.rateNotApplicable === true)) return "N/A";
      return existingCalculation[type];
    } else {
      if (type === "remainingUsage" && quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (quantityNotApplicable === true || rateNotApplicable === true)) return "N/A";
      else return undefined;
    }
  };

  const determineRestrictionValue = (id: string, type: string, postfix: any = undefined) => {
    const existingCalculation = restrictionCalculations.find((calculation) => calculation.id === id);
    if (existingCalculation) {
      return existingCalculation[type];
    } else return undefined;
  };

  const calculateRestrictionColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Restriction Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
          </>
        ),
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (val: string, record: any) => {
          if (!record?.myfa) return <LookupLabel lookupType="restrictiontype" value={val} />;
          else
            return (
              <>
                <LookupLabel lookupType="restrictiontype" value={val} /> - MYFA
              </>
            );
        },
      },
      {
        title: "Start Date",
        key: "startDate",
        dataIndex: "startDate",
        render: (val: any, record: any) => dayjs(record.startDate).format(constants.dateFormat),
      },
      {
        title: "End Date",
        key: "endDate",
        dataIndex: "endDate",
        render: (val: any, record: any) => dayjs(record.endDate).format(constants.dateFormat),
      },
      {
        title: (
          <>
            Term Quantity
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        key: "termQuantity",
        dataIndex: "termQuantity",
        align: "right",
        render: (val: any, record: any) => val,
      },
      {
        title: "Remaining Years",
        key: "remainingYears",
        dataIndex: "remainingYears",
        align: "right",
        render: (val: any, record: any) => determineRestrictionValue(record.id, "remainingYears") ?? "-",
      },
      {
        title: "Remaining Yearly Average",
        align: "center",
        children: [
          selectedCompany?.settings?.metric !== "acrefeet" && {
            title: "Acre Feet",
            align: "right",
            render: (val: any, record: any) => (
              <UnitsConverter fromUnits="gallons" toUnits={selectedCompany?.settings?.metric} value={determineRestrictionValue(record.id, "remainingYearlyAverage")} />
            ),
          },
          {
            title: "Acre Feet",
            align: "right",
            render: (val: any, record: any) => <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineRestrictionValue(record.id, "remainingYearlyAverage")} />,
          },
          {
            title: "Inches Per Acre",
            align: "right",
            render: (val: any, record: any) =>
              determineRestrictionValue(record.id, "inchesPerAcre") !== null ? (
                <UnitsConverter fromUnits="inchesperacre" toUnits="inchesperacre" value={determineRestrictionValue(record.id, "inchesPerAcre")} />
              ) : (
                "N/A"
              ),
          },
        ].filter((x) => x !== false),
      },
    ];

    setRestrictionColumns(tempColumns);
  };

  const calculateWaterRightAvailablePercentage = (record: any) => {
    if (record?.authorizedQuantityNotApplicable === true) return "N/A";
    else return ((determineValue(record?.id, "remainingUsage") / determineValue(record?.id, "availableQuantity")) * 100).toFixed(2);
  };

  const calculateWaterRightColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "fileNumber",
        dataIndex: "fileNumber",
        render: (val: any, record: any) => (
          <>
            <div style={{ paddingLeft: 5 }}>{val}</div>
          </>
        ),
      },
      {
        title: (
          <>
            Authorized
            <br />
            <small>Irrigation Acres</small>
            <br />
            <small>Quantity (Acre Feet)</small>
            <br />
            <small>Rate (GPM)</small>
          </>
        ),
        key: "authorized",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === reportConfig?.year)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? "N/A" : <UnitsConverter fromUnits={record.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} />}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate}</div>}
              </>
            );
          else if (reportConfig?.year < currentYear && record?.referenceYear)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? "N/A" : <UnitsConverter fromUnits={record.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} />}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate}</div>}
              </>
            );
          else
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : `${record?.authorizedIrrigationAcres} *`}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    <UnitsConverter fromUnits={record.authorizedQuantityUnits} toUnits="acrefeet" value={record?.authorizedQuantity} /> *
                  </>
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.authorizedRate} *</div>}
              </>
            );
        },
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>Irrigation Acres</small>
            <br />
            <small>Quantity (Acre Feet)</small>
            <br />
            <small>Rate (GPM)</small>
          </>
        ),
        key: "usage",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === reportConfig?.year)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ?? record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter
                    fromUnits={record.usageQuantity ? record.usageQuantityUnits : record.authorizedQuantityUnits}
                    toUnits="acrefeet"
                    value={record?.usageQuantity ?? record?.authorizedQuantity}
                  />
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ?? record?.authorizedRate}</div>}
              </>
            );
          else if (reportConfig?.year < currentYear && record?.referenceYear)
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ?? record?.authorizedIrrigationAcres}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter
                    fromUnits={record.usageQuantity ? record.usageQuantityUnits : record.authorizedQuantityUnits}
                    toUnits="acrefeet"
                    value={record?.usageQuantity ?? record?.authorizedQuantity}
                  />
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ?? record?.authorizedRate}</div>}
              </>
            );
          else
            return (
              <>
                {record?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.usageIrrigationAcres ? `${record?.usageIrrigationAcres} *` : `${record?.authorizedIrrigationAcres} *`}
                <br />
                {record?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    <UnitsConverter
                      fromUnits={record.usageQuantity ? record.usageQuantityUnits : record.authorizedQuantityUnits}
                      toUnits="acrefeet"
                      value={record?.usageQuantity ?? record?.authorizedQuantity}
                    />{" "}
                    *
                  </>
                )}
                <br />
                {record?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.usageRate ? `${record?.usageRate} *` : `${record?.authorizedRate} *`}</div>}
              </>
            );
        },
      },
      {
        title: (
          <>
            Used
            <br />
            {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
              <>
                <small>
                  (
                  <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
                </small>
                <br />
              </>
            )}
            <small>(Acre Feet)</small>
            <br />
            <small>(Inches / Acre)</small>
          </>
        ),
        align: "right",
        render: (val: any, record: any) => {
          return (
            <>
              {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
                <>
                  <UnitsConverter fromUnits="gallons" toUnits={selectedCompany?.settings?.metric} value={determineValue(record.id, "currentUsage")} />
                  <br />
                </>
              )}
              <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineValue(record.id, "currentUsage")} />
              <br />
              <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={determineValue(record.id, "currentUsage")} devideBy={record.usageIrrigationAcres ?? record.authorizedIrrigationAcres} />
            </>
          );
        },
      },
      {
        title: (
          <>
            Remaining Usage
            <br />
            {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
              <>
                <small>
                  (
                  <LookupLabel lookupType="meterUnits" value={selectedCompanyId && selectedCompany?.settings?.metric} />)
                </small>
                <br />
              </>
            )}
            <small>(Acre Feet)</small>
            <br />
            <small>(Inches Per Acre)</small>
          </>
        ),
        key: "remainingUsage",
        dataIndex: "remainingUsage",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === reportConfig?.year)
            return (
              <>
                {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
                  <>
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={selectedCompany?.settings?.metric}
                      value={determineValue(
                        record.id,
                        "remainingUsage",
                        null,
                        record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                        record?.authorizedQuantityNotApplicable === true ? true : undefined,
                        record?.authorizedRateNotApplicable === true ? true : undefined
                      )}
                    />
                    <br />
                  </>
                )}
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acrefeet"
                  value={determineValue(
                    record.id,
                    "remainingUsage",
                    null,
                    record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                />
                <br />
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acreinches"
                  value={determineValue(
                    record.id,
                    "remainingUsage",
                    null,
                    record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                  devideBy={record.usageIrrigationAcres ?? record.authorizedIrrigationAcres}
                />
              </>
            );
          else if (reportConfig?.year < currentYear && record?.referenceYear)
            return (
              <>
                {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
                  <>
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={selectedCompany?.settings?.metric}
                      value={determineValue(
                        record.id,
                        "remainingUsage",
                        null,
                        record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                        record?.authorizedQuantityNotApplicable === true ? true : undefined,
                        record?.authorizedRateNotApplicable === true ? true : undefined
                      )}
                    />
                    <br />
                  </>
                )}
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acrefeet"
                  value={determineValue(
                    record.id,
                    "remainingUsage",
                    null,
                    record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                />
                <br />
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acreinches"
                  value={determineValue(
                    record.id,
                    "remainingUsage",
                    null,
                    record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                  devideBy={record.usageIrrigationAcres ?? record.authorizedIrrigationAcres}
                />
              </>
            );
          else
            return (
              <>
                {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
                  <>
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={selectedCompany?.settings?.metric}
                      value={determineValue(
                        record.id,
                        "remainingUsage",
                        null,
                        record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                        record?.authorizedQuantityNotApplicable === true ? true : undefined,
                        record?.authorizedRateNotApplicable === true ? true : undefined
                      )}
                    />{" "}
                    *<br />
                  </>
                )}
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acrefeet"
                  value={determineValue(
                    record.id,
                    "remainingUsage",
                    null,
                    record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                />{" "}
                *
                <br />
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acreinches"
                  value={determineValue(
                    record.id,
                    "remainingUsage",
                    null,
                    record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                  devideBy={record.usageIrrigationAcres ?? record.authorizedIrrigationAcres}
                />{" "}
                *
              </>
            );
        },
      },
      {
        title: (
          <>
            Remaining
            <br />
            <small>Days</small>
            <br />
            <small>%</small>
          </>
        ),
        key: "remainingDays",
        dataIndex: "remainingDays",
        align: "center",
        render: (val: any, record: any) => {
          const percentage = calculateWaterRightAvailablePercentage(record);

          if (determineValue(record.id, "remainingDays") === undefined)
            return (
              <>
                - <br /> 0.00%
              </>
            );
          else
            return (
              <>
                <RemainingDays remainingDays={determineValue(record.id, "remainingDays")} /> <br /> {+percentage > 0 ? `${percentage}%` : percentage === "N/A" ? "N/A" : "0.00%"}
              </>
            );
          // else return record?.authorizedIrrigationNotApplicable === true ? <>
          //     N/A
          //     <br />
          //     {+percentage > 0 ? percentage : '0.00'}%
          // </> :
        },
      },
      {
        title: "Remaining Yearly Average",
        key: "remainingYearlyAverage",
        align: "center",
        children: [
          {
            title: "Restriction",
            align: "left",
            render: (val: any, record: any) => renderRestrictionSummaries(record, "name"),
          },
          // TODO: Fix
          selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet"
            ? {
                title: <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />,
                align: "right",
                render: (val: any, record: any) => renderRestrictionSummaries(record, selectedCompany?.settings?.metric),
              }
            : false,
          {
            title: "Acre Feet",
            align: "right",
            render: (val: any, record: any) => renderRestrictionSummaries(record, "acrefeet"),
          },
          {
            title: "Inches / Acre",
            align: "right",
            render: (val: any, record: any) => renderRestrictionSummaries(record, "inchesperacre"),
          },
        ].filter((x) => x !== false),
      },
    ];

    setWaterRightColumns(tempColumns);
  };

  const renderRestrictionSummaries = (record: any, toUnits: string) => {
    const restrictionIds = restrictions
      ?.filter(
        (restriction: any) => restriction?.waterRightIds?.includes(record.id) && dayjs(restriction?.endDate).year() >= reportConfig?.year && dayjs(restriction?.startDate).year() <= reportConfig?.year
      )
      ?.map((restriction: any) => restriction?.id);
    const existingCalculations = restrictionCalculations?.filter((calculation: any) => restrictionIds?.includes(calculation?.id));

    if (existingCalculations.length === 0) return <>-</>;

    return existingCalculations.map((calculation: any, index: number, list: any[]) => {
      return (
        <>
          {toUnits === "name" && calculation?.name}
          {toUnits === "acrefeet" && <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={calculation.remainingYearlyAverage} />}
          {toUnits === "inchesperacre" ? calculation.inchesPerAcre !== null ? <UnitsConverter fromUnits="inchesperacre" toUnits="inchesperacre" value={calculation.inchesPerAcre} /> : "N/A" : ""}
          {toUnits === selectedCompany?.settings?.metric && selectedCompany?.settings?.metric !== "acrefeet" && (
            <UnitsConverter fromUnits="gallons" toUnits={selectedCompany?.settings?.metric} value={calculation.remainingYearlyAverage} />
          )}
          <br />
        </>
      );
    });
  };

  const renderExpandData = (record: any) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 0,
            margin: 0,
          }}
        >
          <WaterRightTagList internalTags={record.internalTags} externalTags={record.externalTags} style={{ marginLeft: 0 }} />
        </div>
        <div style={{ padding: 5 }}>
          {record?.wells?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 60,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Wells:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.wells?.map((x: any) => x).join(", ")}</div>
            </div>
          )}
          {record?.fields?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 60,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Fields:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.fields?.map((x: any) => x).join(", ")}</div>
            </div>
          )}
          {!selectedCompanyId && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 60,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Company:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>
                <CompanyLabel companyId={record?.companyId} />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderRestrictionExpandData = (record: any) => {
    return record?.waterRightIds?.length > 0 ? (
      <div>
        <div style={{ padding: 5 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ fontSize: 12, width: 100, fontWeight: "bold", padding: 0 }}>WaterRights: </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>
              {waterRights
                ?.filter((waterRight) => record?.waterRightIds?.includes(waterRight?.id))
                ?.map((x: any) => x?.fileNumber)
                .join(", ")}
            </div>
          </div>
        </div>
        {!selectedCompanyId && (
          <div style={{ padding: 5 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ fontSize: 12, width: 100, fontWeight: "bold", padding: 0 }}>Company: </div>
              <div style={{ fontSize: 12 }}>
                <CompanyLabel companyId={record?.companyId} />
              </div>
            </div>
          </div>
        )}
      </div>
    ) : undefined;
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedRemainingDaysReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Summary Usage Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loadingWaterRights}
        >
          {reportConfig && (
            <>
              <Card title="Water Rights">
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    // bordered
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.id}
                    loading={loadingWaterRights}
                    columns={waterRightColumns}
                    dataSource={waterRights || []}
                    size="small"
                    pagination={false}
                    footer={() => {
                      if (reportConfig?.year < currentYear)
                        return (
                          <Alert
                            description={`The Authorized, Usage and Remaining Usage columns may contain "*" alongside the values if a snapshot is configured for the water right for any year that is not the current year (${currentYear})`}
                            type="warning"
                            showIcon
                          />
                        );
                      else return undefined;
                    }}
                    expandable={{
                      showExpandColumn: false,
                      defaultExpandAllRows: true,
                      expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                      expandedRowRender: (record: any) => renderExpandData(record),
                    }}
                    summary={(pageData) => {
                      //const authorizedIrrigationAcres = waterRights.map((waterRight) => waterRight?.authorizedIrrigationAcres);
                      const authorizedQuantity = waterRights.map((waterRight) => waterRight?.authorizedQuantity);
                      //const authorizedRate = waterRights.map((waterRight) => waterRight?.authorizedRate);
                      //const usageIrrigationAcres = waterRights.map((waterRight) => (waterRight?.usageIrrigationAcres ? waterRight?.usageIrrigationAcres : waterRight?.authorizedIrrigationAcres));
                      const usageQuantity = waterRights.map((waterRight) => (waterRight?.usageQuantity ? waterRight?.usageQuantity : waterRight?.authorizedQuantity));
                      //const usageRate = waterRights.map((waterRight) => (waterRight?.usageRate ? waterRight?.usageRate : waterRight?.authorizedRate));
                      const used = waterRights.map((waterRight) => convertUnits(determineValue(waterRight.id, "currentUsage"), "gallons", "acrefeet"));
                      const remainingUsage = waterRights.map((waterRight) => convertUnits(determineValue(waterRight.id, "remainingUsage"), "gallons", "acrefeet"));
                      // const remainingDays = waterRights.map(waterRight => determineValue(waterRight.id, 'remainingUsage'));

                      return (
                        <>
                          <Table.Summary.Row className="moveDown">
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align="right">
                              <b>
                                <UnitsConverter fromUnits="acrefeet" toUnits="acrefeet" value={authorizedQuantity.reduce((partialSum: any, num) => (num ? partialSum + num : partialSum), 0)} />
                              </b>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3} align="right">
                              <b>
                                <UnitsConverter fromUnits="acrefeet" toUnits="acrefeet" value={usageQuantity.reduce((partialSum: any, num) => (num ? partialSum + num : partialSum), 0)} />
                              </b>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4} align="right">
                              <b>
                                <UnitsConverter fromUnits="acrefeet" toUnits="acrefeet" value={used.reduce((partialSum: any, num) => (num ? partialSum + num : partialSum), 0)} />
                              </b>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5} align="right">
                              <b>
                                <UnitsConverter fromUnits="acrefeet" toUnits="acrefeet" value={remainingUsage.reduce((partialSum: any, num) => (num ? partialSum + num : partialSum), 0)} />
                              </b>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6}></Table.Summary.Cell>
                            <Table.Summary.Cell index={7}></Table.Summary.Cell>
                            <Table.Summary.Cell index={8}></Table.Summary.Cell>
                            <Table.Summary.Cell index={9}></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                </ConfigProvider>
              </Card>
              <div className="page-break" />
              <Card title="Restrictions">
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.id}
                    loading={loadingRestrictions}
                    columns={restrictionColumns}
                    dataSource={filteredRestrictionList() || []}
                    size="small"
                    pagination={false}
                    expandable={{
                      showExpandColumn: false,
                      defaultExpandAllRows: true,
                      expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                      expandedRowRender: (record: any) => renderRestrictionExpandData(record),
                    }}
                    summary={(pageData) => {
                      const rest = filteredRestrictionList();
                      const termQuantity = rest.map((restriction) => restriction?.termQuantity);
                      const remainingYears = rest.map((restriction) => determineRestrictionValue(restriction.id, "remainingYears"));

                      return (
                        <>
                          <Table.Summary.Row className="moveDown">
                            <Table.Summary.Cell index={0} colSpan={4} align="right"></Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align="right"></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align="right">
                              <b>{termQuantity.reduce((partialSum: any, num) => (num ? partialSum + num : partialSum), 0)}</b>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}></Table.Summary.Cell>
                            <Table.Summary.Cell index={4}></Table.Summary.Cell>
                            <Table.Summary.Cell index={5}></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                </ConfigProvider>
              </Card>
            </>
          )}
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Year">{reportConfig.year}</Descriptions.Item>
                <Descriptions.Item label="Water Rights">{reportConfig.selectedWaterRightNames === "" ? "-" : reportConfig.selectedWaterRightNames}</Descriptions.Item>
                <Descriptions.Item label="Restrictions">{reportConfig.selectedRestrictionNames === "" ? "-" : reportConfig.selectedRestrictionNames}</Descriptions.Item>
                <Descriptions.Item label="Permit Types">{reportConfig?.permitTypes === "" ? "-" : reportConfig?.permitTypes}</Descriptions.Item>
                <Descriptions.Item label="Priority Types">{reportConfig?.priorityTypes === "" ? "-" : reportConfig?.priorityTypes}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
